import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import landingpage from "assets/Images/landingpage.jpg";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface CallToActionDesignProps {
  doSignIn: () => void;
}

const CallToAction = (props: CallToActionDesignProps) => {
  
  const handleLearnMoreClick = () => {
    window.open('https://www.arcadis.com/en/digital/digital-solutions/net-zero-catalyst', '_blank', 'noopener');
  };

 
  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${landingpage})`,
        backgroundSize: "cover",
        height: "75vh",
        color: "white",
        width: "198vh",
      }}
      alignItems="center"
    >
      <div
        style={{
          border: "1px solid #0C080873",
          width: "750px",
          height: "557px",
          paddingLeft: "20px",
          paddingTop: "70px",
          backgroundColor: "#0C080873",
        }}
      >
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} style={{ marginBottom: "10px", padding: "20px" }}>
            <Typography
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Pioneering decarbonization solutions
            </Typography>
            <Typography
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "35px",
                fontWeight: "1000",
                marginBottom: "50px"
              }}
            >
              Net Zero Catalyst
            </Typography>

            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                <CheckIcon />{" "}
                <Typography sx={{ ml: 1 }}>
                  Carbon reduction calculation
                </Typography>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                <CheckIcon />{" "}
                <Typography sx={{ ml: 1 }}>Cost-benefit analysis</Typography>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                <CheckIcon />{" "}
                <Typography sx={{ ml: 1 }}>
                  Effective decarbonization plans
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <ul
              style={{ listStyleType: "none", padding: 0, marginRight: "10px" }}
            >
              <li></li>
            </ul>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLearnMoreClick}
              endIcon={<ArrowForwardIcon sx={{ marginLeft: "8px" }} />}
            >
              Learn More
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "orange",
                fontWeight: "bold",
                marginLeft: "10px",
              }} 
              onClick={props.doSignIn}
              endIcon={
                <ArrowForwardIcon sx={{ color: "orange", marginLeft: "8px" }} />
              } 
            >
              Sign In
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default CallToAction;
