import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { baseYears } from "pages/Dashboards/ProjectRegister/data/ProjectRegisterData";
import MultiSelect from "pages/TargetForm/MultiSelect";
import { OrganizationModel } from "object-models/organization-model";
import { PortfolioFilter } from "../Data/PortfolioFilter";
import { _getTotalEmissionByScopes } from "middlewares/DashboardApi/portfolioView";
import { useAppState } from "context/appState.context";
import { _getOrganizations } from "middlewares/OrganizationApi/organization";
import EmissionByScopePieChat from "./EmissionByScopePieChat";
import EmissionByScopeCategoryBar from "./EmissionByScopeCategoryBar";
import BaselineYearAndTotal from "./BaselineYearAndTotal";
import TotalEmissionsByScope from "./TotalEmissionsByScope";
import { EmissionData, scopesCoveredOptions } from "../Data/PortfolioViewData";
import MultiSelectNew from "./MultSelectNew";
import Spinner from "components/Spinner";
import { Constants } from "constants/constant";

const BaselineEmission = () => {
  const { userRole } = useAppState();

  const [showFilters, setShowFilters] = useState(false);
  const [totalemissions, setTotalemissions] = useState<number>(0);
  const [data, setData] = useState([] as unknown as OrganizationModel);
  const [loading, setLoading] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([
    -1,
  ]);
  const [baseYear, setBaseYear] = useState<number>(Constants.BaseYear);
  const [emissionValues, setEmissionValues] = useState<{
    [key: string]: number;
  }>({});
  const [portfolioFilter, setPortfolioFilter] = useState<PortfolioFilter>({
    OrgId: [],
    BaseYear: baseYear,
    userRole: userRole,
  });

  const [organizationOptions, setOrganizationOptions] = useState<
    { id: number; label: string; value: string }[]
  >([]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [selectedScope, setSelectedScope] = useState(
    scopesCoveredOptions.map((a) => a.id)
  );

  const handleScopeChange = (selectedOptions: number[]) => {
    setSelectedScope(selectedOptions);
    updateEmissionValues(selectedOptions);
  };

  const updateEmissionValues = (selectedOptions: number[]) => {
    const newEmissionValues: { [key: string]: number } = {
      "Scope 1": 0,
      "Scope 2": 0,
      "Scope 3": 0,
    };

    selectedOptions.forEach((option) => {
      switch (option) {
        case 0:
          newEmissionValues["Scope 1"] = Number(data.scope1GHGEmission) || 0;
          break;
        case 1:
          newEmissionValues["Scope 2"] = Number(data.scope2GHGEmission) || 0;
          break;
        case 2:
          newEmissionValues["Scope 3"] = Number(data.scope3GHGEmission) || 0;
          break;
      }
    });

    setEmissionValues(newEmissionValues);
    setTotalemissions(
      newEmissionValues["Scope 1"] +
        newEmissionValues["Scope 2"] +
        newEmissionValues["Scope 3"]
    );
  };
  
  const handleOrganizationChange = (selectedOptions: number[]) => {
    setSelectedOrganizations(selectedOptions);
    setPortfolioFilter((prevFilter) => ({
      ...prevFilter,
      OrgId: selectedOptions,
    }));
  };

  const handleBaseYearChange = (event: SelectChangeEvent<number>) => {
    const selectedYear = event.target.value as number;
    setBaseYear(selectedYear);
    setPortfolioFilter((prevFilter) => ({
      ...prevFilter,
      BaseYear: selectedYear,
    }));
  };

  function getEssentialOrganizationDetails() {
    _getOrganizations(userRole)
      .then((res) => {
        if (res) {
          const formattedOptions = res.map((org) => ({
            id: org.orgId,
            label: org.orgName,
            value: org.orgId.toString(),
          }));
          const selectAllOption = {
            id: 0,
            label: "Select All",
            value: "0",
          };
          setOrganizationOptions([selectAllOption, ...formattedOptions]);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        setLoading(false);
      });
  }

  const getTotalEmissionByScopes = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await _getTotalEmissionByScopes(portfolioFilter);

      if (!res || !Array.isArray(res)) return;

      const emissionSums: EmissionData = {};
      const totalEmissions: EmissionData = {
        "Scope 1": 0,
        "Scope 2": 0,
        "Scope 3": 0,
      };

      const aggregateEmissions = (
        key: string,
        value: number | null | undefined
      ) => {
        if (typeof value === "number") {
          emissionSums[key] = (emissionSums[key] || 0) + value;
        }
      };

      res.forEach((org: Partial<OrganizationModel>) => {
        // Aggregate scope emissions
        totalEmissions["Scope 1"] += Number(org.scope1GHGEmission) || 0;
        totalEmissions["Scope 2"] += Number(org.scope2GHGEmission) || 0;
        totalEmissions["Scope 3"] += Number(org.scope3GHGEmission) || 0;

        // Aggregate other emissions
        Object.entries(org).forEach(([key, value]) => {
          if (key !== "orgId") {
            aggregateEmissions(key, Number(value));
          }
        });
      });

      setData({
        orgId: 1,
        ...emissionSums,
      } as OrganizationModel);

      setEmissionValues(totalEmissions);
      setTotalemissions(
        totalEmissions["Scope 1"] +
          totalEmissions["Scope 2"] +
          totalEmissions["Scope 3"]
      );
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEssentialOrganizationDetails();
    getTotalEmissionByScopes();
  }, []);

  useEffect(() => {
    getTotalEmissionByScopes();
  }, [portfolioFilter]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mr: 5 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }} data-testid="portfolioviewId">
            Portfolio view
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }} data-testid="baselineEmissionsId">
            Baseline Emissions
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      {/* Filters */}
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          style={{
            padding: "16px",
            marginLeft: "5px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: 0,
              width: "100%", // Add this line to make the button take full width
            }}
            data-testid="toggleFiltersButon"
            onClick={toggleFilters}
            aria-pressed={showFilters}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#27272E",
                marginLeft: "4px",
              }}
              data-testid="filtersLabel"
            >
              Filters
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              <IconButton>
                {showFilters ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </div>
          </button>
          {showFilters && (
            <>
              <Grid container alignItems="center">
                <Grid item xs={6} sx={{ mr: 5 }}>
                  <Typography variant="body1" sx={{ mr: 5 }}>
                    Organizations
                  </Typography>
                  <MultiSelectNew
                    dataTestId="organizationSelect"
                    options={organizationOptions}
                    onSelectionChange={handleOrganizationChange}
                    selectedOptions={selectedOrganizations}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="targetyear-dropdown-label">
                      Base Year
                    </FormLabel>
                    <Select
                      value={baseYear}
                      onChange={handleBaseYearChange}
                      sx={{ width: "310px" }}
                      data-testid="baseYearDrodpdown"
                    >
                      <MenuItem value={0}>Select Start Year</MenuItem>
                      {baseYears.map((option, index) => (
                        <MenuItem key={index + option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ m: 1 }}>
                    Scope
                  </Typography>
                  <MultiSelect
                    dataTestId={"scopeSelect"}
                    options={scopesCoveredOptions}
                    onSelectionChange={handleScopeChange}
                    selectedOptions={selectedScope}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      <br />
      <Divider />
      <br />
      {loading ? (
        <Spinner size={80} data-testid="spinner" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4.8}>
              <BaselineYearAndTotal
                totalEmissions={totalemissions}
                baseYear={baseYear}
              />
            </Grid>
            <Grid item xs={7.2}>
              <TotalEmissionsByScope
                emissionValues={emissionValues}
                selectedScope={selectedScope}
              />
            </Grid>
          </Grid>
          <br />
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 4, mb: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Baseline Assessment
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <EmissionByScopePieChat data={data} selectedScope={selectedScope} />
            <EmissionByScopeCategoryBar data={data} />
          </Grid>
        </>
      )}
    </>
  );
};
export default BaselineEmission;
