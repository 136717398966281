import { OrganizationModel } from "object-models/organization-model";
import { OrganizationCardProps, OrganizationMapping } from "pages/HomePage/data/HomePage.type";

import apiHandler from "../apiHandler";

export const _getOrganizations = async (roleName:string) => {
  return apiHandler.get<Array<OrganizationCardProps>>(
    "EssentialOrganizationsDetails/"+roleName
  );
};

export const _getOrganizationNames = async () => {
  return apiHandler.get<Array<OrganizationMapping>>(
    "organizationNames"
  );
};

export const _IsOrganizationExist = async (orgName: string) => {
  return apiHandler.get<boolean>(
    "OrganizationExist/"+orgName
  );
};

export const _addOrganization = async (org: OrganizationModel) => {
  return apiHandler.post<OrganizationModel>("organization", org);
};

export const _updateOrganization = async (org: OrganizationModel) => {
  return apiHandler.put<OrganizationModel>("updateorganization", org);
};

export const _getOrganization = async (byId: number) => {
  return apiHandler.get<OrganizationModel>("organization/" + byId);
};
export const _deleteOrganization = async (orgId: number) => {
  return apiHandler.delete("organization/" + orgId);
};
