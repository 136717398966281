import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { MarginalAbatementCostModel } from "./data/MaginalAbatementResult";
import { generateTableColumn } from "./data/metricsUtils";
import CustomDataGrid from "pages/PortfolioView/ProjectRegister/CustomDataGrid";

/**
 * ProjectRegisterTable component displays a data grid of marginal abatement costs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {MarginalAbatementCostModel[]} props.marginalAbatementCost - Array of marginal abatement cost data.
 * @returns {React.FC} The Project Register Table component.
 */
const ProjectRegisterTable: React.FC<{
  marginalAbatementCost: MarginalAbatementCostModel[];
}> = ({ marginalAbatementCost }) => {
  
  const [rows, setRows] = useState<MarginalAbatementCostModel[]>([]);

  useEffect(() => {
    setRows(marginalAbatementCost);
  }, [marginalAbatementCost]);

  /**
   * Calculate the total values for emission reduction, annual NPV, and MAC.
   * 
   * @returns {Object} The total values object.
   */
  const calculateTotal = () => {
    const totalEmissionsReduction = rows.reduce(
      (total, row) =>
        total + parseInt(String(row.emissionReduction).replace(",", ""), 10),
      0
    );
    const totalAnnualNPV = rows.reduce(
      (total, row) =>
        total + parseInt(String(row.annualNPV).replace(",", ""), 10),
      0
    );
    const totalMac = rows.reduce(
      (total, row) => total + parseInt(String(row.mac).replace(",", ""), 10),
      0
    );

    return {
      reductionMeasureId: "Total",
      measureName: "",
      emissionReduction: totalEmissionsReduction,
      annualNPV: totalAnnualNPV,
      mac: totalMac,
    };
  };

  const columns = [
    {
      field: "reductionMeasureId",
      headerClassName: "table-header",
      headerName: "#S.No",
      minWidth: 40,
    },
    {
      field: "measureName",
      headerClassName: "table-header",
      headerName: "Measure Name",
      minWidth: 160,
    },
    {
      field: "businessUnit",
      headerClassName: "table-header",
      headerName: "Business Unit",
    },
    {
      field: "startYear",
      headerClassName: "table-header",
      headerName: "Start Year",
    },
    {
      field: "execution",
      headerClassName: "table-header",
      headerName: "Execution",
    },
    {
      field: "pace",
      headerClassName: "table-header",
      headerName: "Pace",
    },
    {
      field: "endYear",
      headerClassName: "table-header",
      headerName: "End Year",
    },
    generateTableColumn("emissionReduction", "Emissions Reduction (MTCO₂e)", 208),
    generateTableColumn("annualNPV", "Annual NPV ($)", 100),
    generateTableColumn("mac", "MAC($/MT CO₂e)", 140)
  ];

  const totalRow = calculateTotal();

  return (
    <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
      <Paper variant="outlined">
        <Grid item sx={{ ml: 1, mt: 3, mb: 4 }}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              color: "#05004E",
              width: "100%",
              marginLeft: "8px",
            }}
          >
            Project Register
          </div>
        </Grid>
        <Grid item>
            <CustomDataGrid rows={[...rows, totalRow]} columns={columns} getRowId={(row) => row?.measureName} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProjectRegisterTable;
