import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

/**
 * Interface for options in the multi-select component.
 */
interface Option {
  id: number;
  label: string;
}

/**
 * Props interface for the MultiSelect component.
 */
interface MultiSelectProps {
  options: Option[];
  onSelectionChange: (selectedOptions: number[]) => void;
  selectedOptions: number[];
  dataTestId?: string;
}

/**
 * MultiSelect component to handle multiple selection of options.
 * @param {MultiSelectProps} props - The props for the component.
 * @returns {JSX.Element} MultiSelect component.
 */
const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  onSelectionChange,
  selectedOptions,
  dataTestId = "multi-select",
}) => {
  const [isValid, setIsValid] = useState<boolean>(true);

  /**
   * Handles the change in selected options.
   * @param {SelectChangeEvent<number[]>} event - The change event.
   */
  const handleOptionChange = (event: SelectChangeEvent<number[]>) => {
    const newSelectedOptions = event.target.value as number[];
    onSelectionChange(newSelectedOptions);
    setIsValid(newSelectedOptions.length > 0);
  };

  return (
    <FormControl fullWidth error={!isValid}>
      <Select
        labelId="multi-select-label"
        id="multi-select"
        multiple
        value={selectedOptions}
        onChange={handleOptionChange}
        size="small"
        data-testid={dataTestId}
        renderValue={(selected) => (
          <div>
            {selected.map((value, index) => (
              <span key={value}>
                {options.find((option) => option.id === value)?.label}
                {index < selected.length - 1 && ", "}
              </span>
            ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedOptions.includes(option.id)} />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
