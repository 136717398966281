import { AlertNotificationConfig } from "object-models/shared/alertNotification-config.model";

export default class NotificationHelper {
  static successNotification(message: string): AlertNotificationConfig {
    const successNotification: AlertNotificationConfig = {
      message: message,
      open: true,
      severity: "success",
    };
    return successNotification;
  }
  static warningNotification(message: string): AlertNotificationConfig {
    const warningNotification: AlertNotificationConfig = {
      message: message,
      open: true,
      severity: "warning",
    };
    return warningNotification;
  }
  static errorNotification(message: string): AlertNotificationConfig {
    const errorNotification: AlertNotificationConfig = {
      message: message,
      open: true,
      severity: "error",
    };
    return errorNotification;
  }
  static infoNotification(message: string): AlertNotificationConfig {
    const infoNotification: AlertNotificationConfig = {
      message: message,
      open: true,
      severity: "info",
    };
    return infoNotification;
  }
}
