import { useEffect, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { Typography } from "@mui/material";

export interface LoggedInPersonNameProps {
  variant?: any;
  color?: string;
  fontWeight?: number;
  account:AccountInfo | null; 
}
export const LoggedInPersonName:React.FC<LoggedInPersonNameProps>=({variant,color,fontWeight, account }) =>{
  const arrangeName = (displayName?: string | null): string => {
    if (typeof displayName === "string" && displayName.length > 0) {
      if (displayName.includes(", ")) {
        const parts = displayName.split(", ");
        if (parts.length === 2) {
          return `${parts[1]} ${parts[0]}`;
        }
      }
      return displayName;
    }
    return "";
  };

  const [name, setName] = useState<string>();

  useEffect(() => {
    if (account!=null && account !=undefined) {     
      setName(arrangeName(account.name));
    } else {
      setName("");
    }
  }, [account]);

  return (
    <Typography
      data-testid="loggedInUserName"
      variant={variant ?? "body1"}
      noWrap
      fontWeight={fontWeight ?? 400}
      sx={{ color: color ?? "black", mt: 2 }}
    >
      {name}
    </Typography>
  );
}
export default LoggedInPersonName;