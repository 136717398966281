import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReductionMeasureDesign from "./ReductionMeasures.design";
import SnackBar from "components/SnackBar";
import { AlertsProps } from "components/Alerts/data/Alerts.type";

/**
 * ReductionMeasures component manages the state and logic for displaying and interacting with reduction measures.
 *
 * @component
 * @example
 * return (
 *   <ReductionMeasures />
 * );
 */
export default function ReductionMeasures() {
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedMeasureId, setSelectedMeasureId] = useState(0);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

   /**
   * Handles the closing of the Snackbar.
   * 
   * @param {AlertsProps} state - The new state of the Snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  /**
   * Hides the dialog.
   */
  const hideDialogHandler = () => setDialogOpen(false);

  // Params
  
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Opens the dialog for adding a new measure and updates the title and state.
   */ 
  function onAddMeasureClick() {
    setDialogOpen(true);
    setIsEdit(false);
    setDialogTitle("Add Measure Information");
    navigate(location.pathname, { state: { triggerAddMeasure: false } });
  }

  /**
   * Sets the selected measure ID.
   * 
   * @param {number} selectedMeasure - The ID of the selected measure.
   */
  const setSelectedMeasure = (selectedMeasure: number) => {
    setSelectedMeasureId(selectedMeasure);
  };

  /**
   * Opens the dialog for editing an existing measure and updates the title, edit state, and selected measure ID.
   * 
   * @param {number} selectedMeasure - The ID of the measure to be edited.
   */
  function onEditMeasureClick(selectedMeasure: number) {
    setDialogOpen(true);
    setIsEdit(true);
    setDialogTitle("Edit Measure Information");
    setSelectedMeasureId(selectedMeasure);
  }
  
  function isRefreshEditClose(){
    return 0
  }
  return (
    <React.Fragment>
      <ReductionMeasureDesign
        handleCloseSnackBar={handleCloseSnackBar}
        onAddMeasureClick={onAddMeasureClick}
        hideDialogHandler={hideDialogHandler}
        dialogTitle={dialogTitle}
        dialogOpen={dialogOpen}
        isEdit={isEdit}
        selectedReductionMeasureId={selectedMeasureId}
        onEditMeasureClick={onEditMeasureClick}
        setSelectedMeasure={setSelectedMeasure}
        isRefreshEditClose={isRefreshEditClose}
      ></ReductionMeasureDesign>
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
    </React.Fragment>
  );
}
