import { Grid, Paper } from "@mui/material";
import { OrganizationModel } from "object-models/organization-model";
import { EmissionByScopePie } from "pages/Dashboards/BaselineTargetEmission/EmissionByScopePie.Chart";
import { useEffect, useState } from "react";
import { DataItem, scopesCoveredOptions } from "../Data/PortfolioViewData";

interface EmissionByScopePieChatProps {
  data: OrganizationModel;
  selectedScope:number[];
}

/**
 * A React functional component that renders a pie chart showing emissions by scope.
 * The chart dynamically updates based on the selected scopes and the emission data passed.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {OrganizationModel} props.data - The organization emission data containing scope emission values.
 * @param {number[]} props.selectedScope - The list of selected scope IDs to filter the pie chart.
 */
const EmissionByScopePieChat: React.FC<EmissionByScopePieChatProps> = ({
  data,selectedScope
}) => {

  const defaultWidth = 700;
  const defaultPieChatHeight = 500;

  const [pieChartData, setPieChartData] = useState([] as Array<DataItem>);

 /**
   * Generates and returns the pie chart data based on the emission data.
   *
   * @function
   * @returns {Array<DataItem>} - An array of DataItem objects containing the name and value of each scope's emission.
   */
  const pieData = (): Array<DataItem> => {
    const ndata = [
      {
        name: "Scope 1",
        value: data.scope1GHGEmission ?? 0,
      },
      {
        name: "Scope 2",
        value: data.scope2GHGEmission ?? 0,
      },
      {
        name: "Scope 3",
        value:
          data.scope3GHGEmission != null ? Number(data.scope3GHGEmission) : 0,
      },
    ];

    return ndata;
  };

  /**
   * Reshapes the pie chart data by calling `pieData` and updating the state.
   *
   * @function
   */
  function reshapeData() {
    setPieChartData(pieData);
  }

  // Update pie chart data when `data` changes
  useEffect(() => {
    reshapeData();
  }, [data]);

  // Filter and update the pie chart data based on the selected scopes
  useEffect(() => {
    const scopeFilter = scopesCoveredOptions
      .filter((s) => selectedScope.includes(s.id))
      .map((s) => s.value);
    const newPieData = pieData().filter((d) => scopeFilter.includes(d.name));
    if (newPieData.length > 0) {
      setPieChartData(newPieData);
    } else {
      setPieChartData(pieData());
    }
  }, [selectedScope]);

  return (
    <Grid item xs={5.8}>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <EmissionByScopePie
          width={defaultWidth}
          height={defaultPieChatHeight}
          data={pieChartData}
        />
      </Paper>
    </Grid>
  );

};
export default EmissionByScopePieChat;
