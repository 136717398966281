import { Grid, Typography, Stack } from "@mui/material";

import SingleDropDown from "components/Dropdowns/SingleDropDown/SingleDropDown";
import TitleDefinition from "components/TitleDefinition";
import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";
import { TitleDefinitionConstants } from "constants/constant";

export default function MeasureMCA(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
) {
  return (
    <Grid>
      <Typography
        variant="h6"
        data-testid={"measureReduction"}
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Measure Multi-Criteria Assessment (MCA)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"easeLabel"}
              sx={{ mb: 2 }}
            >
              Ease
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.easeRating}
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.implementationComplexityList}
            value={
              props.draftReductionMeasure.easeRating === undefined
                ? ""
                : props.draftReductionMeasure.easeRating?.toString()
            }
            name={"easeRating"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"reputationLabel"}
              sx={{ mb: 2 }}
            >
              Reputation
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.reputationRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.reputationList}
            value={
              props.draftReductionMeasure.reputationRating === undefined
                ? ""
                : props.draftReductionMeasure.reputationRating?.toString()
            }
            name={"reputationRating"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"climateRiskLabel"}
              sx={{ mb: 2 }}
            >
              Climate Risk
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.climateRiskRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.climateRiskList}
            value={
              props.draftReductionMeasure.climateRiskRating === undefined
                ? ""
                : props.draftReductionMeasure.climateRiskRating?.toString()
            }
            name={"climateRiskRating"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"biodiversityLabel"}
              sx={{ mb: 2 }}
            >
              Biodiversity
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.biodiversityRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.biodiversityList}
            value={
              props.draftReductionMeasure.biodiversityRating === undefined
                ? ""
                : props.draftReductionMeasure.biodiversityRating?.toString()
            }
            name={"biodiversityRating"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"communityLabel"}
              sx={{ mb: 2 }}
            >
              Community
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.communityRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.communityList}
            value={
              props.draftReductionMeasure.communityRating === undefined
                ? ""
                : props.draftReductionMeasure.communityRating?.toString()
            }
            name={"communityRating"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"techReadinessLabel"}
              sx={{ mb: 2 }}
            >
              Technology Readiness
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure
                  .technologyRedinessRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.techReadinessList}
            value={
              props.draftReductionMeasure.technologyRedinessRating === undefined
                ? ""
                : props.draftReductionMeasure.technologyRedinessRating?.toString()
            }
            name={"technologyRedinessRating"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"OpDisruptionLabel"}
              sx={{ mb: 2 }}
            >
              Operational Disruption
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure
                  .operationalDisruptionRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.opDisruptionList}
            value={
              props.draftReductionMeasure.operationalDisruptionRating ===
              undefined
                ? ""
                : props.draftReductionMeasure.operationalDisruptionRating?.toString()
            }
            name={"operationalDisruptionRating"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"HealthSafetyRatingLabel"}
              sx={{ mb: 2 }}
            >
              Health & Safety
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.healthSafetyRating
              }
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.healthSafetyList}
            value={
              props.draftReductionMeasure.healthSafetyRating === undefined
                ? ""
                : props.draftReductionMeasure.healthSafetyRating?.toString()
            }
            name={"healthSafetyRating"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
