import apiHandler from "middlewares/apiHandler";
import { GHGTarget } from "pages/TargetForm/data/GHGTarget";
import { GHGTargetGridProps } from "pages/TargetForm/data/GHGTartgetGrid.type";

export const _addTargetForm = async (targetForm: GHGTarget) => {
  return apiHandler.post<GHGTarget>("GHGTarget", targetForm);
};

export const _getCustomGHGTargetsByOrganizationsId = async (orgId: number | undefined) => {
  return apiHandler.get<Array<GHGTargetGridProps>>(
    `CustomGHGTargets/organization/${orgId}`
  );
};

export const _getGHGTargetsByTargetNumber = async (targetNumber: number | undefined) => {
  return apiHandler.get<GHGTarget>(
    `GHGTargets/${targetNumber}`
  );
};

export const _updateTargetForm = async (targetForm: GHGTarget) => {
  return apiHandler.put<GHGTarget>("GHGTarget", targetForm);
};

export const _deleteGHGTarget = async (targetNumber: number) => {
  return apiHandler.delete("GHGTarget/" + targetNumber);
};