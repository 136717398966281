// External dependencies
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material-UI components
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// Internal dependencies
import FormDialog from "components/Dialog/modal-popup";
import AddUser from "./AddUser";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import SnackBar from "components/SnackBar";
import Search from "components/Search";
import { userRoleModel } from "./data/userRoleModel";
import {
  _getUserRoles,
  _getUserRoleSettings,
} from "middlewares/ManageUsers/manageusers";
import Spinner from "components/Spinner";
import Row from "./Row";
import { OrganizationMapping } from "pages/HomePage/data/HomePage.type";
import { _getOrganizationNames } from "middlewares/OrganizationApi/organization";
import { TitleDefinitionConstants } from "constants/constant";
import { useAppState } from "context/appState.context";
import OrganizationTitle from "pages/OrganizationTitle/OrganizationTitle";
import Blurb from "components/Blurb/Blurb";
import { UserRoleConfigurationModel } from "./data/UserRoleConfigurationModel";

/**
 * UserList component displays a list of users with functionalities to search, add, and edit users.
 *
 * @component
 * @example
 * return (
 *   <UserList />
 * );
 */
const UserList: React.FC = () => {
  // State variables
  const [dialogOpen, setDialogOpen] = useState(false);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userRolesData, setUserRolesData] = useState<Array<userRoleModel>>([]);
  const [filtereduserRoles, setFiltereduserRoles] = useState<
    Array<userRoleModel>
  >([]);
  const [organizations, setOrganizations] = useState<
    Array<OrganizationMapping>
  >([]);
  const [selectedRow, setSelectedRow] = useState<userRoleModel | null>(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [userRoleSettings, setUserRoleSettings] =
    useState<UserRoleConfigurationModel>();
  // Params
  const { OrganizationId } = useParams();
  const { userRole } = useAppState();

  /**
   * Fetches user roles from the API and updates the state.
   * Sets loading state to true while fetching data and false after completion.
   */
  const getUserRoles = async () => {
    try {
      setLoading(true);
      const res = await _getUserRoles(Number(OrganizationId), userRole);
      if (res) {
        setUserRolesData(res);
        setFiltereduserRoles(res);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetches organization names from the API and updates the state.
   * Sets loading state to true while fetching data and false after completion.
   */
  const getOrganizationNames = async () => {
    try {
      setLoading(true);
      const res = await _getOrganizationNames();
      if (res) {
        setOrganizations(res);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const getUserRoleSettings = async () => {
    try {
      setLoading(true);
      const res = await _getUserRoleSettings(Number(OrganizationId));
      if (res) {
        setUserRoleSettings(res);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the click event to edit a user.
   * Sets the selected row and opens the dialog with the title "Edit User".
   *
   * @param {userRoleModel} row - The user role data to be edited.
   */
  const handleEditClick = (row: userRoleModel) => {
    setSelectedRow(row);
    setDialogOpen(true);
    setDialogTitle("Edit User");
  };

  useEffect(() => {
    getUserRoles();
    getOrganizationNames();
    getUserRoleSettings();
  }, []);

  /**
   * Closes the snackbar by setting the success state.
   *
   * @param {AlertsProps} state - The new state for the snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  /**
   * Closes the dialog by setting the dialogOpen state to false.
   */
  const hideDialogHandler = () => setDialogOpen(false);

  /**
   * Filters the user roles based on the search input.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const userFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setFiltereduserRoles(
      userRolesData.filter((item: userRoleModel) =>
        item.userName?.toLowerCase().includes(searchValue)
      )
    );
    setPage(0);
  };

  /**
   * Handles the page change event for the pagination component.
   *
   * @param {unknown} event - The event object.
   * @param {number} newPage - The new page number.
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /**
   * Handles the rows per page change event for the pagination component.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event object.
   */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Renders the user rows in a paginated table format.
   * Displays a message if no users are found.
   *
   * @returns {JSX.Element} The rendered table or no users message.
   */
  const renderusers = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    if (filtereduserRoles.length === 0) {
      return (
        <Grid item xs={11} style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            id="test-typography-01"
            component="div"
            color="gray"
            style={{ fontWeight: "bold" }}
            sx={{ mt: 15, mb: 20, ml: 20 }}
            data-testid="userDefaultText"
          >
            {TitleDefinitionConstants.userMgmtDefaultText}
          </Typography>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Display Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Company
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtereduserRoles
                ?.slice(startIndex, endIndex)
                .map((row, index = 0) => (
                  <Row
                    key={row.userRoleId ? row.userRoleId : `fallback-${index}`}
                    row={row}
                    getUserRoles={getUserRoles}
                    handleEditClick={() => handleEditClick(row)}
                    organizations={organizations}
                    getUserRoleSettings={getUserRoleSettings}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]} // You can customize the options
          component="div"
          count={filtereduserRoles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <OrganizationTitle />
      <Grid item xs={3} md={3}>
        <Typography
          fontSize="24px"
          fontFamily={"Inter"}
          fontWeight={700}
          data-testid="userManagement"
        >
          User Management
        </Typography>
      </Grid>
      <Grid item xs={4} md={4} sx={{ textAlign: "center", mx: "auto" }}>
        <Search label="Search Users" searchFunction={userFilter} />
      </Grid>
      <Grid item xs={1.5} md={1.5} textAlign="right">
        <Button
          variant="contained"
          data-testid="AddUserButton"
          onClick={() => {
            setDialogOpen(true);
            setSelectedRow(null);
            setDialogTitle("Add User");
          }}
        >
          Add User
        </Button>
      </Grid>
      <Grid container spacing={2} sx={{ ml: 2, mt: 5 }}>
        <Blurb
          infoText={`The maximum number of allowed users for each role is ${userRoleSettings?.numberOfAdmins} Admin's, ${userRoleSettings?.numberOfStandardUsers} Standard users, ${userRoleSettings?.numberOfViewers} Viewers`}
          title={"Note:"}
        ></Blurb>
      </Grid>
      <Grid item xs={12} md={12}>
        <br />
        {!loading ? renderusers() : <Spinner size={80} data-testid="spinner" />}
      </Grid>
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
      <FormDialog
        dialogOpen={dialogOpen}
        hideDialogHandler={hideDialogHandler}
        dialogTitle={dialogTitle}
        isFullScreenAllowed={true}
        maxWidth="md"
        childComponent={
          <AddUser
            handleCloseSnackBar={handleCloseSnackBar}
            hideDialogHandler={() => {
              hideDialogHandler();
              setSelectedRow(null);
            }}
            getUserRoles={getUserRoles}
            rowData={selectedRow}
            userRoleSettings={userRoleSettings}
            getUserRoleSettings={getUserRoleSettings}
          />
        }
      ></FormDialog>
    </Grid>
  );
};

export default UserList;
