import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { PriceModel } from "./data/PriceModel";
import { updatePriceValues } from "./data/dataUtils";
import GrowthRow from "./GrowthRow";
import BaseValueRow from "./BaseValueRow";
import UnitsRow from "./UnitsRow";
import { useParams } from "react-router-dom";
import { _addPriceList, _getPriceList } from "middlewares/Settings/prices";
import Spinner from "components/Spinner";
import { TitleDefinitionConstants, UserRoles } from "constants/constant";
import { useAppState } from "context/appState.context";
import OrganizationTitle from "pages/OrganizationTitle/OrganizationTitle";
import SnackBar from "components/SnackBar";
import { AlertsProps } from "components/Alerts/data/Alerts.type";

const StyledTableContainer = styled(TableContainer)({
  maxWidth: "100%",
  overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
  minWidth: 105,
  padding: "9px",
});

/**
 * PricingTable component that displays a table of pricing data and allows for editing and saving.
 *
 * @component
 * @example
 * return (
 *   <PricingTable />
 * )
 */
const PricingTable: React.FC = () => {
  const { OrganizationId } = useParams();
  const { userRole } = useAppState();
  const isUserRole = userRole !== UserRoles.Viewers;

  const [priceData, setPriceData] = useState<PriceModel[]>([]);
  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  /**
   * Fetches the price list from the backend and updates the state with the retrieved data.
   *
   * @async
   * @function getPriceList
   * @returns {Promise<void>}
   */
  const getPriceList = async () => {
    try {
      setLoading(true);
      const res = await _getPriceList(Number(OrganizationId));
      if (res) {
        const updatedData = res.map((item: PriceModel, index: number) => ({
          ...item,
          uniqueId: `${item.energyType || ""}-${index}`,
          priceValue: item.priceValue,
        }));
        setPriceData(updatedData);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPriceList();
  }, [OrganizationId]);

  /**
   * Calculates the range of years present in the price data.
   *
   * @returns {number[]} An array of years.
   */
  const years = useMemo(() => {
    const minYear = Math.min(...priceData.map((item) => item.baseYear));
    const maxYear = Math.max(
      ...priceData.flatMap((item) => item.priceValue.map((pv) => pv.year))
    );
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);
  }, [priceData]);

    /**
   * Handles closing the SnackBar component.
   * @param {AlertsProps} state - The new state for the SnackBar.
   */
    const handleCloseSnackBar = (state: AlertsProps) => {
      setSuccess(state);
    };

  /**
   * Handles changes to price data fields and updates the state accordingly.
   *
   * @function handleDataChange
   * @param {string} id - The unique ID of the item being updated.
   * @param {keyof PriceModel} field - The field in the PriceModel being updated.
   * @param {string | number} value - The new value for the field.
   */
  const handleDataChange = (
    id: string,
    field: keyof PriceModel,
    value: string | number
  ) => {
    setPriceData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.uniqueId === id) {
          const updatedItem = {
            ...item,
            [field]: field === "energyType" ? value : Number(value),
          };
          if (field === "growth" || field === "baseValue") {
            updatePriceValues(
              updatedItem.priceValue,
              updatedItem.baseValue,
              updatedItem.growth
            );
          }
          return updatedItem;
        }
        return item;
      });

      return updatedData;
    });
  };

  /**
   * Handles the save action, which adds the current price data to the backend.
   *
   * @async
   * @function handleSave
   * @returns {Promise<void>}
   */
  const handleSave = async () => {
    try {
      setLoading(true);
      let saveResult = true;

      for (const price of priceData) {
        let res = await _addPriceList([price]);
        if (!res) {
          saveResult = false;
        }
      }

      if (saveResult) {
        setLoading(false);
        const message = "Data Saved Successfully !";
        handleCloseSnackBar({
          show: true,
          severity: "success",
          message: message,
        });
        getPriceList();
      }      
      
      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Spinner size={80} data-testid="spinner" />
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <OrganizationTitle />
            <Grid item xs={12} sx={{ mr: 5 }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold" }}
                data-testid="pricesLabel"
              >
                Prices
              </Typography>
            </Grid>
          </Grid>
          {priceData.length === 0 ? (
            <Grid item xs={11} style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                color="gray"
                style={{ fontWeight: "bold" }}
                sx={{ mt: 15, mb: 20, ml: 20 }}
              >
                {TitleDefinitionConstants.priceEmptyMessage}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid container justifyContent="flex-end" sx={{ mb: 4 }}>
                <Grid item sx={{ ml: 2 }}>
                  {isUserRole && (
                    <Button
                      type="submit"
                      variant="contained"
                      data-testid="btnSave"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
              <StyledTableContainer>
                <Table>
                  <TableBody>
                    <GrowthRow
                      priceData={priceData}
                      onChange={(id, value) =>
                        handleDataChange(id, "growth", value)
                      }
                      isUserRole={isUserRole}
                    />
                    <BaseValueRow
                      priceData={priceData}
                      onChange={(id, value) =>
                        handleDataChange(id, "baseValue", value)
                      }
                      isUserRole={isUserRole}
                    />
                    <UnitsRow priceData={priceData} />
                    {years.map((year) => (
                      <TableRow key={`price-${year}`}>
                        <StyledTableCell>{year}</StyledTableCell>
                        {priceData.map((item) => {
                          const priceValue = item.priceValue.find(
                            (pv) => pv.year === year
                          );
                          return (
                            <StyledTableCell key={`${year}-${item.uniqueId}`}>
                              {priceValue ? priceValue.value : 0}
                            </StyledTableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </>
          )}
          <SnackBar
            show={success.show}
            message={success.message}
            severity={success.severity}
            onclose={() =>
              handleCloseSnackBar({ show: false, severity: "info", message: "" })
            }
          />
        </>
      )}
    </Grid>
  );
};

export default PricingTable;
