import { Grid, Typography, TextField, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import SingleDropDown from "components/Dropdowns/SingleDropDown/SingleDropDown";
import TitleDefinition from "components/TitleDefinition";
import { TitleDefinitionConstants } from "constants/constant";
import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";

/**
 * MeasureDetails component renders a form for entering and editing reduction measure details.
 *
 * @param {Readonly<CreateUpdateReductionMeasuresDesignProps>} props - The properties passed to the component.
 * @returns {JSX.Element} The MeasureDetails form.
 */

export default function MeasureDetails(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
):JSX.Element {
  return (
    <>
      <Typography
        variant="h6"
        data-testid={"measureDetailsLabel"}
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Measure Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" data-testid={"reductionMeasureNameLabel"}>
            Name
            <Typography component="span" sx={{ color: "red" }}>
              *
            </Typography>
          </Typography>

          <TextField
            required
            id="reductionMeasureName"
            inputProps={{ "data-testid": "reductionMeasureName" }}
            fullWidth
            margin="normal"
            size="small"
            name={"reductionMeasureName"}
            value={props.draftReductionMeasure.reductionMeasureName}
            onChange={(e) => {
              props.fieldChangeHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            data-testid={"businessUnitLabel"}
            sx={{ mb: 2 }}
          >
            Business Unit
          </Typography>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.businessUnitList}
            value={props.draftReductionMeasure.businessUnit}
            name={"businessUnit"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"dataSources"}>
              Data Sources
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.DataSources}
              placement="auto"
            />
          </Stack>
          <TextField
            id="dataSources"
            inputProps={{ "data-testid": "dataSources" }}
            fullWidth
            margin="normal"
            size="small"
            name={"dataSources"}
            value={props.draftReductionMeasure.dataSources}
            onChange={(e) => {
              props.fieldChangeHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"measureStage"}
              sx={{ mb: 2 }}
            >
              Stage
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.Stage}
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            onChange={props.fieldChangeHandler}
            list={props.measureStageList.map((g) => g.value)}
            value={props.draftReductionMeasure.measureStage}
            name={"measureStage"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"measureCategoryLabel"}
              sx={{ mb: 2 }}
            >
              Measure Category
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <a href="/glossary" target="_blank">
              <InfoOutlinedIcon
                fontSize="small"
                sx={{ color: "#e4610f", mx: 0.4, my: 0.2 }}
              />
            </a>
          </Stack>
          <SingleDropDown
            required
            onChange={props.fieldChangeHandler}
            list={props.measureCategoryList.map((g) => g.value)}
            value={props.draftReductionMeasure.measureCategory}
            name={"measureCategory"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"measureTypeLabel"}
              sx={{ mb: 2 }}
            >
              Measure Type
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <a href="/glossary" target="_blank">
              <InfoOutlinedIcon
                fontSize="small"
                sx={{ color: "#e4610f", mx: 0.4, my: 0.2 }}
              />
            </a>
          </Stack>
          <SingleDropDown
            disabled={props.draftReductionMeasure.measureCategory.length < 1}
            required
            onChange={props.fieldChangeHandler}
            list={props.measureTypeList.map((g) => g.value)}
            value={props.draftReductionMeasure.measureType}
            name={"measureType"}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            data-testid={"descriptionLabel"}
            sx={{ mt: 1 }}
          >
            Description
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            size="small"
            multiline
            rows={3}
            inputProps={{
              maxLength: 300,
              "data-testid": "measureDescription",
            }}
            value={props.draftReductionMeasure.measureDescription}
            onChange={props.fieldChangeHandler}
            name={"measureDescription"}
          />
        </Grid>
      </Grid>
    </>
  );
}
