import { AccountInfo } from "@azure/msal-browser";
import { Constants } from "../constants/constant"

export class AppStorage {

  setAccessToken(token: string): void {
    sessionStorage.setItem(Constants.SessionStorageKey_Token, token);
  }

  getAccessToken(): string {
    const token = sessionStorage.getItem(Constants.SessionStorageKey_Token);
    if (token) {
      return token;
    } else {
      return "";
    }
  }

  removeTokenFromStorage(): void {
    sessionStorage.removeItem(Constants.SessionStorageKey_Token);
  }

  setExpirationTime(exp: Date): void {
    sessionStorage.setItem(
      Constants.SessionStorageKey_TokenExpirationTime,
      exp.toISOString()
    );
  }

  getExpirationTime(): string {
    const time = sessionStorage.getItem(
      Constants.SessionStorageKey_TokenExpirationTime
    );

    if (time) {
      return time;
    }
    return "";
  }

  removeExpirationTime(): void {
    sessionStorage.removeItem(Constants.SessionStorageKey_TokenExpirationTime);
  }

  getUserAccount():AccountInfo|null {
    const account= sessionStorage.getItem(Constants.SessionStorageKey_UserAccount);
    if (account) {
      return JSON.parse(account) as AccountInfo;
    } else {
      return null;
    }
  }

  setUserAccount(account: AccountInfo|null) {
    return sessionStorage.setItem(Constants.SessionStorageKey_UserAccount,JSON.stringify(account));
  }

  removeUserAccountFromStorage(): void {
    sessionStorage.removeItem(Constants.SessionStorageKey_UserAccount);
  }

}

const appStorage = new AppStorage();
export default appStorage;