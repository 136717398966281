import apiHandler from "middlewares/apiHandler";

/**
 * Fetches the Marginal Abatement Cost based on the provided filter metrics.
 * 
 * @param {Object} filterMetric - The metrics to filter the Marginal Abatement Cost.
 * @returns {Promise<any>} - A promise that resolves to the response from the API.
 */
export const _getMarginalAbatementCost = async (filterMetric: any) => {
  return apiHandler.post<any>("dashboard/marginalabatementcost", filterMetric);
};
