import apiHandler from "middlewares/apiHandler";
import { EFMappingElectricityModel } from "object-models/shared/EFMappingElectricityModel";
import { MasterPropertyModel } from "object-models/shared/master-property.model";

export class MasterPropertyApi {
  apiHandler = apiHandler;
  baseURL = "/api/";

  getMasterPropertyListByCategory(
    category: string
  ): Promise<Array<MasterPropertyModel>> {
    return this.apiHandler
      .get<Array<MasterPropertyModel>>("masterpropertylist/" + category)
      .then((masterList: Array<MasterPropertyModel>) => {
        masterList.forEach((item) => {
          item.id = item.masterPropertyId;
        });
        return masterList;
      });
  }

  getMasterPropertyListByCategoryAndType(
    category: string,
    type: string
  ): Promise<Array<MasterPropertyModel>> {
    return this.apiHandler
      .get<Array<MasterPropertyModel>>(
        "masterpropertylist/" + category + "/" + type
      )
      .then((masterList: Array<MasterPropertyModel>) => {
        masterList.forEach((item) => {
          item.id = item.masterPropertyId;
        });
        return masterList;
      });
  }

  getMasterPropertyListForGlossary(): Promise<Array<MasterPropertyModel>> {
    return this.apiHandler
      .get<Array<MasterPropertyModel>>("glossaylist")
      .then((masterList: Array<MasterPropertyModel>) => {
        masterList.forEach((item) => {
          item.id = item.masterPropertyId;
        });
        return masterList;
      });
  }
  _getCountries = async () => {
    return apiHandler.get<Array<EFMappingElectricityModel>>(`masterpropertylist/countries/`);
  };
}
const masterPropertyApi = new MasterPropertyApi();
export default masterPropertyApi;
