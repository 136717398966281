import React from "react";
import { Grid, Paper, Stack } from "@mui/material";
import smoke from "../../../assets/Icons/smoke.png";
import waste from "../../../assets/Icons/waste.png";
import pollution from "../../../assets/Icons/pollution.png";
import { scopesCoveredOptions } from "../Data/PortfolioViewData";
import EmissionScope from "./EmissionScope";

interface TotalEmissionsByScopeProps {
  emissionValues: { [key: string]: number };
  selectedScope: number[];
}

/**
 * A React functional component that displays total emissions by scope.
 * It shows emission values for Scope 1, Scope 2, and Scope 3, and icons for each scope.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {Object.<string, number>} props.emissionValues - Emission values for each scope, mapped by scope key (e.g., 'Scope 1', 'Scope 2').
 * @param {number[]} props.selectedScope - An array of selected scope IDs to display.
 */
const TotalEmissionsByScope: React.FC<TotalEmissionsByScopeProps> = ({
  emissionValues,
  selectedScope,
}) => {
  /**
   * Renders the emission value for a given scope.
   *
   * @function
   * @param {string} scopeKey - The key of the scope (e.g., 'Scope 1').
   * @returns {string} - The formatted emission value as a string, or "N/A" if not available.
   */
  const renderEmissionValue = (scopeKey: string) => {
    return emissionValues[scopeKey]?.toLocaleString() || "N/A";
  };

  /**
   * Retrieves the corresponding icon for the given scope.
   *
   * @function
   * @param {string} scopeValue - The value of the scope (e.g., 'Scope 1', 'Scope 2').
   * @returns {string} - The path to the corresponding icon image.
   */
  function getScopeIcon(scopeValue: string) {
    switch (scopeValue) {
      case scopesCoveredOptions[0].value:
        return smoke;
      case scopesCoveredOptions[1].value:
        return pollution;
      case scopesCoveredOptions[2].value:
        return waste;
      default:
        return pollution;
    }
  }

  return (
    <Paper variant="outlined">
      <Grid item sx={{ m: 1 }}>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "600",
            color: "#05004E",
            width: "100%",
          }}
        >
          Total Emissions By Scope (MT CO₂e)
        </div>
      </Grid>
      <Stack direction={"row"} justifyItems={"center"}>
        <EmissionScope
          selectedScope={selectedScope}
          renderEmissionValue={renderEmissionValue}
        />
      </Stack>
    </Paper>
  );
};

export default TotalEmissionsByScope;
