import React from "react";
import { Grid } from "@mui/material";
import ConsolidatedStackedAreaChat from "./ConsolidatedStackedAreaChat";

type RawData = {
  year: string;
  [key: string]: number | string;
};

type InteractiveGlidepathProps = {
  data: RawData[];
  width: number;
  height: number;
};

const InteractiveGlidepath: React.FC<InteractiveGlidepathProps> = ({
  data,
  width,
  height,
}) => {
  
  return (
    <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
      <ConsolidatedStackedAreaChat data={data} width={width} height={height} chartType="InteractiveGlidepath"/>
    </Grid>
  );
};

export default InteractiveGlidepath;
  