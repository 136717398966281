import React from "react";
//MUI
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
//types
import { SanckBarProps } from "./data/SnackBar.type";
import { IconButton, Slide, SlideProps } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type TransitionProps = Omit<SlideProps, "direction">;

/**
 * TransitionDown component provides a slide down transition for the Snackbar.
 *
 * @param {TransitionProps} props - The props for the transition component.
 * @returns {JSX.Element} The rendered Slide component.
 */
function TransitionDown(props: Readonly<TransitionProps>) {
  return <Slide {...props} direction="down" />;
}

/**
 * SnackBar component displays a customizable Snackbar with an Alert.
 *
 * @param {SanckBarProps} props - The props for the component.
 * @param {boolean} props.show - Whether the Snackbar is visible.
 * @param {string} props.message - The message to display in the Alert.
 * @param {string} props.severity - The severity of the Alert ('error', 'warning', 'info', 'success').
 * @param {() => void} props.onclose - The function to call when the Snackbar is closed.
 * @returns {JSX.Element} The rendered SnackBar component.
 */
function SnackBar(props: Readonly<SanckBarProps>) {
  return (
    <Snackbar
      open={props.show}
      onClose={props.onclose}
      autoHideDuration={5000}
      TransitionComponent={TransitionDown}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        severity={props.severity}
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
        onClose={props.onclose}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={props.onclose}
            sx={{ ml: -2 }} 
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {props.message}
      </Alert>
      
    </Snackbar>
  );
}

export default SnackBar;
