import { PriceValue } from "./PriceModel";

export const updatePriceValues = (
  priceValues: PriceValue[],
  baseValue: number,
  growth: number
): void => {
  let currentValue = baseValue;

  // Iterate through the existing array and update only the `value` field
  for (const priceValue of priceValues) {
    priceValue.value = parseFloat(currentValue.toFixed(2));
    currentValue += (currentValue * growth) / 100;
  }
};
