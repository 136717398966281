import { useMemo, useRef } from "react";
import * as d3 from "d3";
import styles from "./pie-chart.module.css";

type DataItem = {
  name: string;
  value: number;
};
type PieChartProps = {
  width: number;
  height: number;
  data: DataItem[];
};

const MARGIN_X = 190;
const MARGIN_Y = 50;
const INFLEXION_PADDING = -10; // space between donut and label inflexion point

const colors = ["#e0ac2b", "#a53253", "#59b5a0"];

/**
 * Component for displaying a pie chart of emissions by scope.
 */
export const EmissionByScopePie = ({ width, height, data }: PieChartProps) => {
  const radius = Math.min(width - 2 * MARGIN_X, height -2 * MARGIN_Y) / 2.5;
  const ref = useRef<SVGGElement>(null);
  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any, DataItem>().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcGenerator = d3.arc();

  const shapes = pie.map((grp, i) => {
    if (grp.value > 0) {
      // First arc is for the pie
      const sliceInfo = {
        innerRadius: 50,
        outerRadius: radius,
        startAngle: grp.startAngle,
        endAngle: grp.endAngle,
      };
      const centroid = arcGenerator.centroid(sliceInfo);
      const slicePath = arcGenerator(sliceInfo);

      // Second arc is for the legend inflexion point
      const inflexionInfo = {
        innerRadius: radius + INFLEXION_PADDING,
        outerRadius: radius + INFLEXION_PADDING,
        startAngle: grp.startAngle,
        endAngle: grp.endAngle,
      };
      const inflexionPoint = arcGenerator.centroid(inflexionInfo);

      const isRightLabel = centroid[0] > -5;
      const labelPosX = inflexionPoint[0] + 50 * (isRightLabel ? 1 : -1);
      const textAnchor = isRightLabel ? "start" : "end";
      const label =
        grp.data.name + " (" + grp.value.toLocaleString("en-US") + ")";
      
        return (
        <g
          key={grp.data.name}
          className={styles.slice}
          onMouseEnter={() => {
            if (ref.current) {
              ref.current.classList.add(styles.hasHighlight);
            }
          }}
          onMouseLeave={() => {
            if (ref.current) {
              ref.current.classList.remove(styles.hasHighlight);
            }
          }}
        >
          <path
            d={slicePath ?? undefined}
            fill={colors[i]}
            // onClick={() => handlePieClick(grp.data.name)}
          />
          <circle cx={centroid[0]} cy={centroid[1]} r={2} />
          <line
            x1={centroid[0]}
            y1={centroid[1]}
            x2={inflexionPoint[0]}
            y2={inflexionPoint[1]}
            stroke={"black"}
            fill={"black"}
          />
          <line
            x1={inflexionPoint[0]}
            y1={inflexionPoint[1]}
            x2={labelPosX}
            y2={inflexionPoint[1] - (labelPosX % 3) * 5}
            stroke={"black"}
            fill={"black"}
          />
          <text
            x={labelPosX + (isRightLabel ? 2 : -2)}
            y={inflexionPoint[1] - (labelPosX % 3) * 5}
            textAnchor={textAnchor}
            dominantBaseline="middle"
            fontSize={14}
          >
            {label}
          </text>
        </g>
      );
    }
  });
  const title = (
    <text
      x={width / 3.6}
      y={5}
      textAnchor="center"
      alignmentBaseline="central"
      fontSize={14}
      stroke="#808080"
    >
      Emissions by Scope
    </text>
  );
  return (
    <svg width={width} height={height} style={{ display: "inline-block" }}>
      {title}
      <g
        transform={`translate(${width / 2.5}, ${height / 2})`}
        ref={ref}
        className={styles.container}
      >
        {shapes}
      </g>
    </svg>
  );
};
