import React  from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { useAppState } from "context/appState.context";

/**
 * Component to display the Organization Name is all pages
 *
 * @returns {JSX.Element} The rendered component.
 */
const OrganizationTitle: React.FC = () => {
  const { orgName } = useAppState();

  return (
      <Grid container spacing={2} sx={{ mt: 0, mb: 2, paddingLeft: 2 }}>
        <Grid item container direction="row">
            <Typography
              fontWeight={700}
              data-testid="organizationNamelabel"
              mr={0.5}
              sx={{ color: "#718096" }}
              fontSize="20px"
            >
              Org Name:
            </Typography>

            <Typography
              fontSize="20px"
              fontFamily={"Inter"}
              fontWeight={700}
              data-testid="organizationName"
            >
              {orgName}
            </Typography>
          </Grid>
      </Grid>
  );
};
export default OrganizationTitle;
