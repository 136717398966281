import { Grid, Typography } from "@mui/material";

/**
 * Component for displaying the header of the Scenario View page.
 * This header includes a title and a subtitle for the Net Zero Carbonization Model.
 * 
 * @returns {JSX.Element} The Scenario View Header component.
 */
const ScenarioViewHeader = () => {
  return (
    <Grid item xs={12} sx={{ mr: 5, mb: 5 }}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              color: "#0C0808",
              padding: "8px",
            }}
            data-testid="netZeroCarbonizationLabel"
          >
            Net Zero Carbonization Model
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#0C0808",
              marginLeft: "6px",
            }}
            data-testid="scenarioView"
          >
            Scenario View
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScenarioViewHeader;
