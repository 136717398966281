 import { RedirectRequest } from "@azure/msal-browser";

const host: string|undefined = process.env.REACT_APP_HOST;
const redirect: string|undefined =process.env.REACT_APP_REDIRECT_URL;
const tenant = process.env.REACT_APP_TENANT_NAME;
const clientID = process.env.REACT_APP_CLIENT_ID;
const signInSignUp = process.env.REACT_APP_SIGN_UP_SIGN_IN_POLICY;
  
export const msalConfig = {
  auth: {
      clientId: clientID as string,
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${signInSignUp}`,
      knownAuthorities: [`${tenant}.b2clogin.com`],
      redirectUri: `${redirect}`,
      postLogoutRedirectUri: `${host}`
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
};

export const loginRequest : RedirectRequest = {
  scopes: ['openid', `https://${tenant}.onmicrosoft.com/${clientID}/access_as_user`]
};