import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";

import group1 from "../../../assets/Images/dashboard/group1.png";
import calendar from "../../../assets/Icons/calendar.png";
import co2 from "../../../assets/Icons/co2.png";

interface BaselineYearAndTotalProps {
  totalEmissions: number;
  baseYear: number;
}

/**
 * A React functional component that displays the baseline year and total emissions in a styled card format.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {number} props.totalEmissions - The total emissions value to be displayed.
 * @param {number} props.baseYear - The baseline year to be displayed.
 */
const BaselineYearAndTotal: React.FC<BaselineYearAndTotalProps> = ({
  totalEmissions,
  baseYear,
}) => {
  return (
    <Paper variant="outlined">
      <Grid item sx={{ m: 1 }}>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "600",
            color: "#05004E",
            width: "100%",
          }}
        >
          Baseline Year & Total Emissions (MT CO₂e)
        </div>
      </Grid>
      <Stack direction={"row"}>
        <EmissionCard
          title={baseYear}
          subtitle="Baseline Year"
          imageSrc={calendar}
        />
        <EmissionCard
          title={totalEmissions.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
          subtitle="Total Emissions"
          imageSrc={co2}
        />
      </Stack>
    </Paper>
  );
};

/**
 * A reusable card component to display emission-related data.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {string | number} props.title - The title to be displayed in the card.
 * @param {string} props.subtitle - The subtitle to be displayed in the card.
 * @param {string} props.imageSrc - The source of the image to be displayed.
 */
const EmissionCard: React.FC<{
  title: string | number;
  subtitle: string;
  imageSrc: string;
}> = ({ title, subtitle, imageSrc }) => (
  <Grid
    item
    xs={6}
    sx={{
      boxShadow: 1,
      p: 1,
      m: 1,
      borderRadius: 5,
      backgroundImage: `url(${group1})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
    style={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Grid item>
      <Stack direction={"column"}>
        <Typography sx={{ fontSize: "24px", flex: "1" }}>
          <b>{title}</b>
        </Typography>
        <div>{subtitle}</div>
      </Stack>
    </Grid>
    <Grid item alignSelf={"end"}>
      <img
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        src={imageSrc}
        alt={subtitle}
      />
    </Grid>
  </Grid>
);

export default BaselineYearAndTotal;
