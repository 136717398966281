// EmissionScope.tsx
import React from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
import group1 from "../../../assets/Images/dashboard/group1.png";
import smoke from "../../../assets/Icons/smoke.png";
import waste from "../../../assets/Icons/waste.png";
import pollution from "../../../assets/Icons/pollution.png";
import { scopesCoveredOptions } from "../Data/PortfolioViewData";

interface EmissionScopeProps {
  selectedScope: number[];
  renderEmissionValue: (scopeKey: string) => string;
}

/**
 * Renders emission data for selected scopes or a message if no scope is selected.
 *
 * @component
 * @param {number[]} selectedScope - Array of selected scope IDs.
 * @param {Object.<string, number>} emissionValues - Emission values for each scope.
 */
const EmissionScope: React.FC<EmissionScopeProps> = ({
  selectedScope,
  renderEmissionValue,
}) => {
  const getScopeIcon = (scopeValue: string) => {
    switch (scopeValue) {
      case scopesCoveredOptions[0].value:
        return smoke;
      case scopesCoveredOptions[1].value:
        return pollution;
      case scopesCoveredOptions[2].value:
        return waste;
      default:
        return pollution;
    }
  };

  if (selectedScope.length > 0) {
    const selectedScopeList = scopesCoveredOptions.filter((s) =>
      selectedScope.includes(s.id)
    );
    return (
      <>
        {selectedScopeList.map((s) => (
          <Grid
            key={s.id}
            item
            xs={4}
            sx={{
              boxShadow: 1,
              p: 1,
              m: 1,
              borderRadius: 5,
              backgroundImage: `url(${group1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item>
              <Stack direction={"column"}>
                <Typography
                  sx={{ fontSize: "24px", flex: "1" }}
                  data-testid={s.value}
                >
                  <b>{renderEmissionValue(s.value)}</b>
                </Typography>
                <div>{s.label}</div>
              </Stack>
            </Grid>
            <Grid item alignSelf={"end"}>
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={getScopeIcon(s.value)}
                alt={s.value + "Icon"}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  } else {
    return (
      <Box sx={{ m: 1, mr: 2, mb: 2 }}>
        <Typography variant="h6" color={"primary"}>
          Select Scope in the filter
        </Typography>
      </Box>
    );
  }
};

export default EmissionScope;
