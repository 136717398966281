import React, { useState, useEffect } from "react";
import ListReductionMeasuresDesign from "./ListReductionMeasures.design";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReductionMeasureListModel } from "object-models/ReductionMeasures";

import { useParams } from "react-router-dom";
import {
  _deleteReductionMeasure,
  _getReductionMeasureList,
} from "middlewares/ReductionMeasure/ReductionMeasureApi";
import ViewReductionMeasuresLayout from "../ViewReductionMeasures/ViewReductionMeasuresLayout";
import Spinner from "components/Spinner/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertDialog from "components/AlertDialog";
import { ConfirmationMessages, UserRoles } from "constants/constant";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import SnackBar from "components/SnackBar";
import { useAppState } from "context/appState.context";
import { Tooltip } from "@mui/material";

/**
 * Props for the ListReductionMeasures component.
 * @typedef {Object} ListReductionMeasuresProps
 * @property {Function} onAddMeasureClick - Callback function triggered when the add measure button is clicked.
 * @property {Function} onEditMeasureClick - Callback function triggered when a measure is to be edited.
 * @property {boolean} refreshView - Flag indicating whether the view needs to be refreshed.
 */
interface ListReductionMeasuresProps {
  onAddMeasureClick: () => void;
  onEditMeasureClick: (selectedMeasureId: number) => void;
  refreshView: boolean;
}

/**
 * ListReductionMeasures component displays a list of reduction measures and handles various actions like viewing, editing, and deleting measures.
 *
 * @param {ListReductionMeasuresProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export default function ListReductionMeasures(
  props: Readonly<ListReductionMeasuresProps>
) {
  const { OrganizationId } = useParams();
  const { userRole } = useAppState();
  
  const [loading, setLoading] = useState(false);
  const defaultReductionMeasurelist: Array<ReductionMeasureListModel> = [];
  const [reductionMeasureList, setReductionMeasureList] = useState(
    defaultReductionMeasurelist
  );
  const [isTableView, setIsTableView] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [reductionMeasureId, setReductionMeasureId] = useState(0);
  const [reductionMeasureName, setReductionMeasureName] = useState("");
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });
  const [selectedReductionMeasureId, setSelectedReductionMeasureId] =
    useState(0);

  /**
   * Handles the view action for a reduction measure.
   *
   * @param {number} id - The ID of the reduction measure to view.
   */
  const handleView = (id: any) => {
    setSelectedReductionMeasureId(id);
    setIsTableView(false);
  };

  /**
   * Handles the edit action for a reduction measure.
   *
   * @param {number} id - The ID of the reduction measure to edit.
   */
  const handleEdit = (id: any) => {
    setSelectedReductionMeasureId(id);
    setOpenEditModal(true);
  };

  /**
   * Hides the edit dialog.
   */
  const hideDialogHandler = () => setOpenEditModal(false);

  /**
   * Handles the back action to return to the table view.
   */
  const OnBackClick = () => {
    setSelectedReductionMeasureId(0);
    setIsTableView(true);
  };

  /**
   * Fetches the list of reduction measures based on the organization ID.
   */
  const getReductionMeasureListByOrganizationId = async () => {
    try {
      setLoading(true);
      const res = await _getReductionMeasureList(+(OrganizationId ?? "0"));
      if (res) {
        const modifiedRes = res.map((record, index) => ({
          ...record,
          rowId: `${index + 1}`, // Generate a unique rowId based on the index
        }));
        setReductionMeasureList(modifiedRes);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Opens the delete confirmation dialog.
   */
  const openDialog = () => {
    setOpenModal(true);
  };

  /**
   * Closes the delete confirmation dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
   * Handles the snackbar close action.
   *
   * @param {AlertsProps} state - The state to set for the snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  const isRefreshEditClose = () => {
    setLoading(true);
    getReductionMeasureListByOrganizationId();
  };

  /**
   * Deletes the selected reduction measure.
   */
  const deleteReductionMeasure = async () => {
    try {
      const res = await _deleteReductionMeasure(reductionMeasureId);
      if (res) {
        getReductionMeasureListByOrganizationId();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  /**
   * Confirms the deletion of the reduction measure.
   */
  const handleConfirm = () => {
    deleteReductionMeasure();
    handleClose();
    handleCloseSnackBar({
      show: true,
      severity: "success",
      message: "Deleted Successfully !",
    });
  };

  /**
   * Handles the delete action for a reduction measure.
   *
   * @param {number} reductionMeasureId - The ID of the reduction measure to delete.
   * @param {string} reductionMeasureName - The name of the reduction measure to delete.
   */
  const handleDeleteClick = (
    reductionMeasureId: number,
    reductionMeasureName: string
  ) => {
    openDialog();
    setReductionMeasureId(reductionMeasureId);
    setReductionMeasureName(reductionMeasureName);
  };

  useEffect(() => {
    setLoading(true);
    getReductionMeasureListByOrganizationId();
  }, []);

  useEffect(() => {
    if (props.refreshView === true) {
      setLoading(true);
      getReductionMeasureListByOrganizationId();
    }
  }, [props.refreshView]);

  const columns = [
    {
      field: "rowId",
      headerClassName: "table-header",
      headerName: "Id",
      minWidth: 20,
      renderCell: (params: any) => (
        <div style={{ textAlign: "center", width: "10%" }}>{params.value}</div>
      ),
    },
    {
      field: "reductionMeasureName",
      headerClassName: "table-header",
      headerName: "Measure Name",
      minWidth: 130,
      renderCell: (params: any) => (
        <Tooltip title={params.value || ""} disableHoverListener={params.value?.length <= 25}>
          <div
            style={{
              textAlign: "center",
              width: "80%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.value}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "measureCategory",
      headerClassName: "table-header",
      headerName: "Measure Category",
      minWidth: 200,
    },
    {
      field: "measureType",
      headerClassName: "table-header",
      headerName: "Measure Type",
      minWidth: 120,
    },
    {
      field: "measureStage",
      headerClassName: "table-header",
      headerName: "Stage",
      minWidth: 150,
    },
    {
      field: "businessUnit",
      headerClassName: "table-header",
      headerName: "Business Unit",
      minWidth: 120,
    },
    {
      field: "measureDescription",
      headerClassName: "table-header",
      headerName: "Description",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      headerClassName: "table-header",
      headerName: "Actions",
      width: 100,
      minWidth: 100,
      renderCell: (params: any) => (
        <div>
          <span
            title="View Measure"
            style={{ cursor: "pointer", paddingRight: 5 }}
          >
            <VisibilityIcon
              data-testid={"view" + params.row.id}
              style={{ color: "#e4610f" }}
              onClick={() => handleView(params.row.reductionMeasureId)}
            />
          </span>
          {userRole !== UserRoles.Viewers && (
            <>
              <span
                title="Edit Measure"
                style={{ cursor: "pointer", paddingRight: 5 }}
              >
                <EditIcon
                  style={{ color: "#e4610f" }}
                  onClick={() => handleEdit(params.row.reductionMeasureId)}
                  data-testid={"editiconTestId" + params.row.id}
                />
              </span>
              <span title="Delete Measure" style={{ cursor: "pointer" }}>
                <DeleteOutlineIcon
                  onClick={() =>
                    handleDeleteClick(
                      params.row.reductionMeasureId,
                      params.row.reductionMeasureName
                    )
                  }
                  data-testid={"deleteIconTestId" + params.row.id}
                  style={{
                    color: "#e4610f",
                    cursor: "pointer",
                  }}
                />
              </span>
            </>
          )}
        </div>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  let renderedComponent;

  if (isTableView) {
    if (!loading) {
      renderedComponent = (
        <ListReductionMeasuresDesign
          onAddMeasureClick={props.onAddMeasureClick}
          openEditModal={openEditModal}
          selectedReductionMeasureId={selectedReductionMeasureId}
          columns={columns}
          reductionMeasureList={reductionMeasureList}
          handleCloseSnackBar={handleCloseSnackBar}
          hideDialogHandler={hideDialogHandler}
          isRefreshEditClose={isRefreshEditClose}
        />
      );
    } else {
      renderedComponent = <Spinner size={80} data-testid="spinner" />;
    }
  } else {
    renderedComponent = (
      <ViewReductionMeasuresLayout
        onEditMeasureClick={props.onEditMeasureClick}
        selectedReductionMeasureId={selectedReductionMeasureId}
        reductionMeasureIdList={reductionMeasureList.map(
          (rm) => rm.reductionMeasureId
        )}
        onBackClick={OnBackClick}
        refreshView={props.refreshView}
      />
    );
  }

  return (
    <React.Fragment>
      {renderedComponent}
      <AlertDialog
        open={openModal}
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        organizationName={reductionMeasureName}
        confirmationMessage={ConfirmationMessages.reductionMeasureConfirmation}
      />
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
    </React.Fragment>
  );
}
