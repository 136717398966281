import React, { useMemo, useState } from "react";
//MUI
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
//Service
import { RandomNumber } from "services";
//Type
import { SingleDropdownSearchType } from "./data/singleDropdownSearch.type";

/**
 * Checks if the searchText is contained in the given text.
 *
 * @param {string} text - The text to search within.
 * @param {string} searchText - The text to search for.
 * @returns {boolean} - Returns true if searchText is found in text, false otherwise.
 */
const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

/**
 * SingleDropDownSearch component to display a searchable dropdown menu.
 * 
 * @param {Readonly<SingleDropdownSearchType>} props - The properties for the SingleDropDownSearch component.
 * @returns {JSX.Element} The rendered SingleDropDownSearch component.
 */
function SingleDropDownSearch({
  inputLabel,
  value,
  name,
  list,
  searchField,
  required,
  others,
  onChange,
  valueLabel,
}: Readonly<SingleDropdownSearchType>) {
  const [searchText, setSearchText] = useState("");
  //A call back function if search text or list changes will be called. To filter list based on entered text.

  /**
   * Gets a custom label based on the name.
   * 
   * @param {string | undefined} name - The name to get a custom label for.
   * @returns {string} - The custom label.
   */
  const getCustomLabel = (name: string | undefined) => {
    const labelMap: Record<string, string> = {
      theme: "Custom Theme",
      subtheme: "Custom Subtheme",
    };

    return (name && labelMap[name]) || "Custom Criterion";
  };

    /**
   * Filters the list based on the searchText and optionally adds an "Others" option.
   * 
   * @returns {any[]} - The filtered list of options.
   */
  const displayedOptions = useMemo(() => {
    if (searchField) {
      const tempList = list?.filter((option) =>
        containsText(`${option?.[searchField]}`, searchText)
      );
      // Add Others to list
      others &&
        tempList?.push({ id: 1000000, label: getCustomLabel(name), value: "" });
      return tempList;
    } else {
      let tempList = list ? [...list] : [];
      others &&
        tempList?.push({ id: 1000000, label: getCustomLabel(name), value: "" });
      return tempList;
    }
  }, [searchText, list, others, searchField]);

  return (
    <FormControl fullWidth>
      <Select
        style={{ background: "white" }}
        data-testid={name}
        MenuProps={{
          autoFocus: false,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              overflow: "auto",
              maxHeight: "40vh",
              maxWidth: "35vh",
            },
          },
          MenuListProps: {
            disablePadding: true,
          },
        }}
        labelId={`search-select-label-${searchField}`}
        id={`search-select-${searchField}`}
        onChange={onChange}
        onClose={() => setSearchText("")}
        renderValue={() => value}
        value={value || ""}
        name={name}
        required={required}
        size="small"
      >
        <ListSubheader sx={{ padding: "0px 10px 0px 10px" }}>
          <FormHelperText sx={{ padding: "3px" }}></FormHelperText>
          <TextField
            size="small"
            autoFocus
            placeholder="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            sx={{ borderRadius: "6px" }}
          />
        </ListSubheader>
        {displayedOptions?.map((option, i) => (
          <MenuItem
            key={option?.[searchField] ?? option?.label ?? RandomNumber() + 1}
            value={
              (valueLabel && option?.[valueLabel]) ||
              option?.[searchField] ||
              option?.label
            }
          >
            {option?.[searchField] || option?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SingleDropDownSearch;
