import { TLFMetricsData } from "./TLFMetricsData";

export const generateYearsArray = (
  startYear: number,
  endYear: number,
  step: number
): number[] => {
  const years: number[] = [];
  for (let year = startYear; year <= endYear; year += step) {
    years.push(year);
  }
  return years;
};

const extractMeasures = (apiResponse: any, isGlidepath: boolean): string[] => {
  if (isGlidepath) {
    return apiResponse.map((item: any) => item.measureName);
  }
  return apiResponse.timelineFinancialMetric.map(
    (item: any) => item.measureName
  );
};

const findEmissionReduction = (
  year: number,
  measure: string,
  apiResponse: any,
  isGlidepath: boolean
): number => {
  const measureData = isGlidepath
    ? apiResponse.find(
        (item: any) => item.measureName === measure && item.year === year
      )
    : apiResponse.timelineFinancialMetric.find(
        (item: any) => item.measureName === measure && item.year === year
      );
  return measureData ? measureData.emissionReduction : 0;
};

const createDataPoint = (
  year: number,
  measures: string[],
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData => {
  const dataPoint: TLFMetricsData = { year: year.toString() };
  measures.forEach((measure) => {
    dataPoint[measure] = findEmissionReduction(
      year,
      measure,
      apiResponse,
      isGlidepath
    );
  });
  return dataPoint;
};

const generateMetricsData = (
  years: number[],
  measures: string[],
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData[] => {
  return years.map((year) =>
    createDataPoint(year, measures, apiResponse, isGlidepath)
  );
};

export const generateData = (
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData[] => {
  const years = generateYearsArray(2020, 2050, 1);
  const measures = extractMeasures(apiResponse, isGlidepath);
  return generateMetricsData(years, measures, apiResponse, isGlidepath);
};

export const generateTableColumn = (
  field: string,
  headerName: string,
  minWidth: number
) => ({
  field,
  headerClassName: "table-header",
  headerName,
  minWidth,
  flex: 1,
  renderCell: (params: any) => (
    <div style={{ textAlign: "center", width: "80%" }}>
      {params.value.toLocaleString("en-US")}
    </div>
  ),
});

const extractOrgNames = (apiResponse: any): string[] => {
  return apiResponse.timelineFinancialMetric.map(
    (item: any) => item.orgName
  );
};

const findOrgLevelEmissionReduction = (
  year: number,
  org: string,
  apiResponse: any
): number => {
  const measureData = apiResponse.timelineFinancialMetric.find(
        (item: any) => item.orgName === org && item.year === year
      );
  return measureData ? measureData.emissionReduction : 0;
};

const createOrgLevelDataPoint = (
  year: number,
  orgs: string[],
  apiResponse: any
): TLFMetricsData => {
  const dataPoint: TLFMetricsData = { year: year.toString() };
  orgs.forEach((org) => {
    dataPoint[org] = findOrgLevelEmissionReduction(
      year,
      org,
      apiResponse
    );
  });
  return dataPoint;
};

const generateOrgLevelMetricsData = (
  years: number[],
  orgs: string[],
  apiResponse: any
): TLFMetricsData[] => {
  return years.map((year) =>
    createOrgLevelDataPoint(year, orgs, apiResponse)
  );
};

export const generateOrgLevelData = (
  apiResponse: any
) : TLFMetricsData[] =>  {
  const years = generateYearsArray(2020, 2050, 1);
  const orgs = extractOrgNames(apiResponse);
  return generateOrgLevelMetricsData(years, orgs, apiResponse);
}