// Main Icons
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import GamesOutlinedIcon from "@mui/icons-material/GamesOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import SettingsIcon from "@mui/icons-material/Settings";
import CardTravelIcon from "@mui/icons-material/CardTravel";

const getIconColor = (menuLabel: string): "primary" | "secondary" => {
  return menuLabel === localStorage.getItem("HighlightsMenu")
    ? "primary"
    : "secondary";
};

const buildMenu = () => [
  {
    id: 0,
    icon: <AddBoxOutlinedIcon color={getIconColor("Organization Details")} />,
    label: "Organization Details",
    link: "Organization-Details",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
  },
  {
    id: 1,
    icon: <GamesOutlinedIcon color={getIconColor("GHG Targets")} />,
    label: "GHG Targets",
    link: "Target-Form",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
  },
  {
    id: 2,
    icon: <ViewInArOutlinedIcon color={getIconColor("Reduction Measures")} />,
    label: "Reduction Measures",
    link: "Reduction-Measures",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
  },
  {
    id: 3,
    icon: <GroupIcon color={getIconColor("User Management")} />,
    label: "User Management",
    link: "user-management",
    allowedRoles: ["Super Admin", "Admin"],
  },
  {
    id: 4,
    icon: <DashboardIcon color={getIconColor("Dashboards")} />,
    label: "Dashboards",
    link: "baselinetargetemissions",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
    children: [
      {
        id: 1,
        icon: <PieChartIcon color={getIconColor("Dashboards")} />,
        label: "Baseline Emissions",
        link: "baselinetargetemissions",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
      {
        id: 2,
        icon: <PieChartIcon color={getIconColor("Dashboards")} />,
        label: "Project Register",
        link: "projectregister",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
      {
        id: 3,
        icon: <PieChartIcon color={getIconColor("Dashboards")} />,
        label: "Scenario View",
        link: "scenarioView",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
    ],
  },
  {
    id: 5,
    icon: <SettingsIcon color={getIconColor("Settings")} />,
    label: "Settings",
    link: "prices",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
    children: [
      {
        id: 1,
        icon: <PieChartIcon color={getIconColor("Settings")} />,
        label: "Prices",
        link: "prices",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
      {
        id: 2,
        icon: <PieChartIcon color={getIconColor("UserRole")} />,
        label: "UserRole",
        link: "userrole",
        allowedRoles: ["Super Admin"],
      },
    ],
  },
  {
    id: 6,
    icon: <CardTravelIcon color={getIconColor("Portfolio view")} />,
    label: "Portfolio view",
    link: "baselineEmissions",
    allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
    children: [
      {
        id: 1,
        icon: (
          <PieChartIcon color={getIconColor("Portfolio Baseline Emissions")} />
        ),
        label: "Portfolio Baseline Emissions",
        link: "baselineEmissions",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
      {
        id: 2,
        icon: (
          <PieChartIcon color={getIconColor("Portfolio Project Register")} />
        ),
        label: "Portfolio Project Register",
        link: "portFolioProjectRegister",
        allowedRoles: ["Super Admin", "Admin", "Standard User", "Viewers"],
      },
      {
        id: 3,
        icon: <PieChartIcon color={getIconColor("Capex Plan")} />,
        label: "Capex Plan",
        link: "capexplan",
        allowedRoles: ["Super Admin", "Admin", "Standard User"],
      },
    ],
  },
];

const SimpleMenu = () => {
  const isPortfolioview = localStorage.getItem("Portfolioview");
  const allMenus = buildMenu();

  // If "Portfolio view" was clicked, show only that menu item
  if (isPortfolioview === "1") {
    return allMenus.filter((menu) => menu.label === "Portfolio view");
  }

  // Otherwise, return all menu items
  return allMenus;
};

export default SimpleMenu;
