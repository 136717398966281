import React from "react";
import { Typography, Grid } from "@mui/material";

interface CategoryInfoProps {
  category: string;
  value: number | string ;
  label: string | undefined;
}

/**
 * Displays category information with a label and value.
 * 
 * @component
 * @param {CategoryInfoProps} props - The properties for the CategoryInfo component.
 * @returns {JSX.Element} The rendered CategoryInfo component.
 */
const CategoryInfo: React.FC<CategoryInfoProps> = ({
  category,
  label,
  value,
}) => {
  return (
    <Grid item xs={4} sx={{ mb: 3.5 }}>
      <Typography
        variant="body2"
        sx={{ fontSize: 14, color: "GrayText", fontWeight: "light", mb: 1.5 }}
      >
        {category}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 18 }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 18 }}>
        {value ?? "0"}
      </Typography>
    </Grid>
  );
};

export default CategoryInfo;
