import React, { useState } from 'react';
import { Grid, Paper, Typography, IconButton, Button } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MultiSelectNew from '../BaselineEmission/MultSelectNew';

interface FilterSectionProps {
  organizationOptions: Array<{ id: number; label: string; value: string }>;
  selectedOrganizations: number[];
  onOrganizationChange: (selectedOptions: number[]) => void;
  onSearch: () => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  organizationOptions,
  selectedOrganizations,
  onOrganizationChange,
  onSearch,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" style={{ padding: '16px', margin: '10px 5px' }}>
        <button
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            padding: 0,
            width: '100%',
          }}
          data-testid="toggleFiltersButton"
          onClick={toggleFilters}
          aria-pressed={showFilters}
        >
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '20px', color: '#27272E', marginLeft: '4px' }}>
            Filters
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton>{showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          </div>
        </button>
        {showFilters && (
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={5}>
              <Typography variant="body1" sx={{ mr: 8 }}>
                Organizations
              </Typography>
              <MultiSelectNew
                dataTestId="organizationSelect"
                options={organizationOptions}
                onSelectionChange={onOrganizationChange}
                selectedOptions={selectedOrganizations}
              />
            </Grid>
            {selectedOrganizations.length > 0 && (
              <Grid item xs={6} container justifyContent="flex-end">
                <Button type="submit" variant="contained" data-testid="btnSearch" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default FilterSection;