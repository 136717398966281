import apiHandler from "middlewares/apiHandler";
import { UserRoleConfigurationModel } from "pages/ManageUsers/data/UserRoleConfigurationModel";
import { userRoleModel } from "pages/ManageUsers/data/userRoleModel";

export const _addUser = async (userRoleModel: userRoleModel) => {
  return apiHandler.post<userRoleModel>("UserManager", userRoleModel);
};

export const _getUserRoles = async (orgId: number | undefined,userRole:string) => {
  return apiHandler.get<Array<userRoleModel>>(
    `UserManager/${orgId}/${userRole}`
  );
};

export const _getUserRole = async (userEmail: string) => {
  return apiHandler.post<string>("UserManagerRole", userEmail);
};

export const _deleteUserRole = async (userRoleId: number,orgId:number) => {
  return apiHandler.delete(`UserManager/${userRoleId}/${orgId}`);
};

export const _updateUser = async (userRoleModel: userRoleModel) => {
  return apiHandler.put<userRoleModel>("UserManager", userRoleModel);
};

export const _getUserRoleSettings = async (orgId: number) => {
  return apiHandler.get<UserRoleConfigurationModel>(
    `UserRoleSettings/${orgId}`
  );
};