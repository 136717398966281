import { MasterPropertyModel } from "object-models/shared/master-property.model";

export function getEnergyUnits(
    energyTypeList: Array<MasterPropertyModel>,
    selectedType: string
  ): string[] {
    const eType = energyTypeList.find((e) => e.value === selectedType);
    if (eType) {
      if (eType.note && eType.note?.length > 0) {
        if (eType.note.includes(",")) {
          return eType.note.split(",");
        } else {
          return [eType.note];
        }
      } else return [];
    }
    return [];
  }