import { Box, Paper, Typography, Icon } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/**
 * Interface for the Blurb component properties.
 */
export interface BlurbModel {
  infoText?: string;
  infoJSX?: React.JSX.Element;
  isClosable?: boolean;
  isHidable?: boolean;
  title?: string;
}

/**
 * Blurb component to display informational text or JSX with optional title,
 * hide and close functionality.
 *
 * @param {Readonly<BlurbModel>} props - The properties for the Blurb component.
 * @returns {JSX.Element} The rendered Blurb component.
 */
export default function Blurb(props: Readonly<BlurbModel>) {
  const [hidden, setHidden] = useState(false);
  const [closed, setClosed] = useState(false);

  return (
    <React.Fragment>
      {!closed ? (
        <Paper
          hidden={closed}
          variant="outlined"
          data-testid="blurbPaper"
          sx={{
            mb: 1,
            display: "inline-flex",
            border: "none",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {props.title && props.title.length > 0 ? (
            <Typography
              variant="body1"
              sx={{
                color: "primary.main",
                mb: 1,
                mt: 1,
              }}
            >
              {props.title}
            </Typography>
          ) : (
            <Icon
              data-testid="blurbIcon"
              component={hidden ? InfoIcon : InfoOutlinedIcon}
              onClick={() => {
                if (props.isHidable) {
                  setHidden(!hidden);
                }
              }}
              sx={{
                color: "primary.main",
                m: 1,
              }}
            />
          )}

          <Box hidden={hidden}>
            {props.infoText && (
              <Typography
                data-testid="blurb-message-info"
                sx={{ p: 1, whiteSpace: "pre-line" }}
              >
                {props.infoText}
              </Typography>
            )}
            {props.infoJSX ? props.infoJSX : ""}
          </Box>
          <Box hidden={hidden || !props.isClosable}>
            <CloseIcon
              data-testid="blurb-close"
              onClick={() => {
                setClosed(true);
              }}
              fontSize="small"
            ></CloseIcon>
          </Box>
        </Paper>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
