export interface MarginalAbatementCostModel {
    reductionMeasureId: number;
    measureName: string;
    businessUnit?: string | null;
    startYear?: number | null;
    pace?: number | null;
    execution?: number | null;
    endYear?: number | null;
    emissionReduction?: number | null | string;
    annualNPV?: number | null;
    mac?: number | null;
}

export interface DashboardMetric {
    emissionReduction?: number | null;
    annualNPV?: number | null;
    capexInvestment?: number | null;
    opexInvestment?: number | null;
    mac?: number | null;
}

export interface MaginalAbatementResult {
    MarginalAbatementCost?: MarginalAbatementCostModel[] | null;
    Metrics?: DashboardMetric | null;
}

export const truncateText = (text: string, maxLength: number = 25): string => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };