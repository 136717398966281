// Import your `DataItem` type from wherever it is defined.

import { DataItem } from "./PortfolioViewData";

export const createDataItem = (
  name: string,
  value: number | undefined
): DataItem => ({
  name,
  value: value ?? 0,
});

/**
 * Generates and returns the data for the bar chart based on the emission data.
 *
 * @function
 * @param {any} data - The data object containing emission values.
 * @returns {Array<DataItem>} - An array of `DataItem` objects for each emission category.
 */
export const barData = (data: any): Array<DataItem> => [
  createDataItem("Purchased Goods And Services", data.purchasedGoodsAndServices),
  createDataItem("Capital Goods", data.capitalGoods),
  createDataItem("Fuel and Energy Related Activities", data.fuelandEnergyRelatedActivities),
  createDataItem("Upstream Transportation And Distribution", data.upstreamTransportationAndDistribution),
  createDataItem("Waste Generated In Operations", data.wasteGeneratedinOperations),
  createDataItem("Business Travel", data.businessTravel),
  createDataItem("Employee Commuting", data.employeeCommuting),
  createDataItem("Upstream Leased Assets", data.upstreamLeasedAssets),
  createDataItem("Downstream Transportation And Distribution", data.downstreamTransportationAndDistribution),
  createDataItem("Processing of Sold Products", data.processingofSoldProducts),
  createDataItem("Use of Sold Products", data.useofSoldProducts),
  createDataItem("End of Life Treatment of Sold Products", data.endofLifeTreatmentofSoldProducts),
  createDataItem("Downstream Leased Assets", data.downstreamLeasedAssets),
  createDataItem("Franchises", data.franchises),
  createDataItem("Investments", data.investments),
  createDataItem("Other Upstream", data.otherUpstream),
  createDataItem("Other Downstream", data.otherDownstream),
];
