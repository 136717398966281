import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Breakpoint, IconButton } from "@mui/material";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { ReactNode } from "react";

interface FormDialogProps {
  dialogOpen: boolean;
  hideDialogHandler: () => void;
  dialogTitle: string;
  childComponent: ReactNode;
  maxWidth?: Breakpoint;
  isFullScreenAllowed?: boolean;
  height?: string;
  onFullScreenButtonClick?: () => void;
}

/**
 * FormDialog component to display a dialog with optional fullscreen capability.
 * 
 * @param {Readonly<FormDialogProps>} props - The properties for the FormDialog component.
 * @returns {JSX.Element} The rendered FormDialog component.
 */
export default function FormDialog(props: Readonly<FormDialogProps>) {
  const [fullScreenMode, setFullScreenMode] = React.useState(false);

  /**
   * Handles the closing of the dialog.
   * 
   * @param {any} event - The event object.
   * @param {string} reason - The reason for closing.
   */
  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    props.hideDialogHandler();
  };

  /**
   * Enables fullscreen mode for the dialog.
   */
  function fullScreen() {
    setFullScreenMode(true);
    if (props.onFullScreenButtonClick !== undefined) {
      props.onFullScreenButtonClick();
    }
  }

  /**
   * Disables fullscreen mode for the dialog.
   */
  function exitFullScreen() {
    setFullScreenMode(false);
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (props.dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.dialogOpen]);

  const fullscreenButton = props.isFullScreenAllowed && (
    <IconButton
      data-testid={
        fullScreenMode ? "exitFullScreenDialog" : "enterFullScreenDialog"
      }
      onClick={fullScreenMode ? exitFullScreen : fullScreen}
      sx={{
        position: "absolute",
        right: 40,
        top: 8,
      }}
    >
      {fullScreenMode ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
    </IconButton>
  );

  return (
    <Dialog
      open={props.dialogOpen}
      onClose={handleClose}
      fullWidth
      fullScreen={fullScreenMode}
      maxWidth={props.maxWidth ? props.maxWidth : "xl"}
    >
      <DialogTitle sx={{ p: 1.5 }} data-testid={"dialogTitle"}>
        {props.dialogTitle}
        {fullscreenButton}

        <IconButton
          aria-label="close"
          onClick={(e) => handleClose(e, "")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: props.height }} dividers={true}>
        {props.childComponent}
      </DialogContent>
    </Dialog>
  );
}
