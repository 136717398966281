import { createTheme } from '@mui/material/styles';


export const theme = createTheme({

    // Basic Palette Theme
    palette: {
        primary: {
            main: "#e4610f",

        },
        secondary: {
            main: "#1d1d1d",
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#fafbfd",
        },
        contrastThreshold: 3,
    },
    typography: {
        fontFamily: "Inter, Helvetica, Arial, Calibri, sans-serif;",
    },

    components: {
        // AppBar Style Start
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "#FFFFFF",
                    borderBottom:"1px solid #EBF2FA"
                }
            },
        },

        // Drawer Start
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    top: 65,
                    border: "none",
                }
            }
        },
        // Drawer End

        // Appbar Style End
        // Card Style Start
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderRadius: "15px"
                }
            }
        },

        // Card Style End
        // Paper Style Start
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                }
            }
        },

        // Paper Style End

        // List Styles Start
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontFamily: "Inter, Helvetica, Arial, Calibri, sans-serif;",
                    color: "#44474d",
                    fontWeight: 600,
                    fontSize: "14px",
                    opacity: "0.9",
                    padding: "5px",

                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#e4610f",
                    opacity: "0.8",
                    fontSize: '1.125rem',
                }
            }
        },
        // List Styles End

        // Button Style Start
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "6px"
                }
            }
        },
        // Button Style End


        // Dialog Styles Start
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "10px"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#e4610f",
                    color: "white"
                }
            }
        },
        // Dialog Styles End

        // MUI Tab Start
        MuiTab: {
            styleOverrides: {
                root: {
                    opacity: 0.9,
                    color: "#718096",
                    textTransform: "none",
                    fontSize: "16px"
                }
            }
        },
        //MUI Tab End

        // MUI Table Start
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#E9EFF5"
                }
            }

        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "1px solid #A0AEC0",
                    
                },
            },
        },
        //MUI Table End

        //MUI Select start
        MuiSelect: {
            styleOverrides: {
                select: {
                    borderRadius: "4px",
                }
            }
        },
        //MUI Select end

        //MUI Popover start
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: "4px",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.18)"
                }
            }
        },
        //MUI Popover end

        // MUI CSS Baseline start
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    //scrollbarColor: "#ffffff",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "rgba(250, 251, 253, 1)",
                        width: "10px",
                        height: "10px"
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: "#E7E7E7",
                        minHeight: 50,
                        border: "1px solid #E7E7E7",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#E7E7E7",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#E7E7E7",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#E7E7E7",
                    }
                },
            },
        },
        // MUI CSS Baseline end
    }
});