import { Grid, Paper, Stack, Typography } from "@mui/material";
import group1 from "../../../assets/Images/dashboard/group1.png";
import { Metric } from "./data/Metric";

interface ProjectMetricsProps {
  metrics: Metric[];
}

/**
 * ProjectMetrics component displays a grid of project metrics.
 *
 * @component
 * @param {ProjectMetricsProps} props - The component props.
 * @param {Metric[]} props.metrics - Array of metric data.
 * @returns {React.FC} The Project Metrics component.
 */
const ProjectMetrics: React.FC<ProjectMetricsProps> = ({ metrics }) => {
  /**
   * Generate a list of metric cards to be displayed.
   *
   * @returns {JSX.Element[]} Array of metric cards.
   */
  function getMetrics() {
    return metrics.map((s) => {
      return (
        <Grid
          key={s.id}
          item
          xs={4}
          sx={{
            boxShadow: 1,
            p: 1,
            m: 1,
            borderRadius: 5,
            backgroundImage: `url(${group1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"column"} sx={{ flexGrow: 1 }}>
            <div>{s.label}</div>
            <Typography sx={{ fontSize: "24px" }} data-testid={s.value}>
              <b>{s.value.toLocaleString("en-US")}</b>
            </Typography>
          </Stack>
          <Grid item alignSelf={"end"}>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={s.src}
              alt={s.value + "Icon"}
            />
          </Grid>
        </Grid>
      );
    });
  }
  return (
    <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
      <Paper variant="outlined">
        <Grid item sx={{ m: 1 }}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              color: "#05004E",
              width: "100%",
              marginLeft: "8px",
            }}
          >
            Metrics
          </div>
        </Grid>
        <Stack direction={"row"} justifyItems={"center"} sx={{ padding: 2 }}>
          {getMetrics()}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default ProjectMetrics;
