import { Grid, MenuItem, Paper, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectRegisterModel } from "../Data/ProjectRegisterModel";
import { generateTableColumn } from "pages/Dashboards/ProjectRegister/data/metricsUtils";
import CustomDataGrid from "./CustomDataGrid";

const ProjectRegisterTable: React.FC<{
  projectRegisterModel: ProjectRegisterModel[];
  onScenarioChange: (orgId: number, selectedScenario: string) => void;
  
}> = ({ projectRegisterModel,onScenarioChange  }) => {
  const [rows, setRows] = useState<ProjectRegisterModel[]>([]);
  

  useEffect(() => {
    setRows(projectRegisterModel);
  }, [projectRegisterModel]);

  const columns = [
    {
      field: "rowId",
      headerClassName: "table-header",
      headerName: "#S.No",
      minWidth: 40,
    },
    {
      field: "organizationName",
      headerClassName: "table-header",
      headerName: "Organization Name",
      minWidth: 190,
    },
    {
      field: "scenarios",
      headerClassName: "table-header",
      headerName: "Scenarios",
      minWidth: 190,
      flex: 1,
      renderCell: (params: any) => (
        <Select
          value={params.row.scenarioSection}
          fullWidth
          onChange={(event) => {
            const selectedScenario = event.target.value;
            onScenarioChange(params.row.orgId, selectedScenario); // Call the passed function
          }}
        >
          {params.value.map((scenario: string, index: number) => (
            <MenuItem key={index+1} value={scenario}>
              {scenario}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    generateTableColumn("emissionReduction", "Emissions Reduction (MTCO₂e)", 208),
    generateTableColumn("annualNPV", "Annual NPV ($)", 100),
    generateTableColumn("mac", "MAC($/MT CO₂e)", 140),
    generateTableColumn("capExInvestment", "CapEx Investment", 140),
    generateTableColumn("opEx", "OPEX", 140),
  ];

  return (
    <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
      <Paper variant="outlined">
        <Grid item sx={{ ml: 1, mt: 3, mb: 4 }}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              color: "#05004E",
              width: "100%",
              marginLeft: "8px",
            }}
          >
            Project Register
          </div>
        </Grid>
        <Grid item>
            <CustomDataGrid rows={rows} columns={columns} getRowId={(row) => row?.organizationName} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProjectRegisterTable;
