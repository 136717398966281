
import MuiDrawer from '@mui/material/Drawer';

import { styled } from '@mui/material/styles';
// Components
import { openedMixin, closedMixin } from "./Mixin"

// Constant
import { LeftDrawerWidth } from 'constants/constant';

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: LeftDrawerWidth,
      flexShrink: 0,
      top: "none",
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
  