import React from "react";
import { SingleDropdownType } from "./data/singleDropdown.type";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { RandomNumber } from "services";

/**
 * SingleDropDown component to display a dropdown menu.
 * 
 * @param {Readonly<SingleDropdownType>} props - The properties for the SingleDropDown component.
 * @returns {JSX.Element} The rendered SingleDropDown component.
 */
function SingleDropDown({
  inputLabel,
  value,
  name,
  list,
  inputHelp,
  required,
  size,
  disabled,
  onChange,
  useId = false,
}: Readonly<SingleDropdownType>) {
  return (
    <FormControl fullWidth variant="outlined" size={size}>
      {inputLabel && !value && (
        <InputLabel
          variant={inputLabel ? "outlined" : "standard"}
          id={`search-select-label-${name}`}
        >{`${inputLabel} ${required ? "*" : ""}`}</InputLabel>
      )}
      <Select
        variant="outlined"
        MenuProps={{ autoFocus: false }}
        data-testid={name}
        labelId={`search-select-label-${name}`}
        id={`search-select-${name}`}
        onChange={onChange}
        value={value}
        name={name}
        required={required}
        disabled={disabled}
        style={{ background: "white" }}
        size="small"
      >
        {list?.map((option, index) => (
          <MenuItem
            data-testid={`${name}-option-${index}`}
            key={option?.text ?? option ?? RandomNumber() + 1}
            value={useId ? option?.id : option?.value ?? option}
          >
            {option?.text ?? option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{inputHelp}</FormHelperText>
    </FormControl>
  );
}

export default SingleDropDown;
