import { useRef, useState } from "react";
//mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
//icons

import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import EyeOutlineIcon from "assets/Icons/EyeOutlineIcon.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

//images
import projectImage1 from "assets/Images/Organization-1.png";

// Type
import { OrganizationCardProps } from "./data/HomePage.type";
import { useNavigate } from "react-router-dom";

//services
import { ConvertDate } from "services";
import styled from "@emotion/styled";
import AlertDialog from "components/AlertDialog";
import { _deleteOrganization } from "middlewares/OrganizationApi/organization";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { ConfirmationMessages, UserRoles } from "constants/constant";
import { useAppState } from "context/appState.context";

const StyledImg = styled.img`
  cursor: pointer;
`;

/**
 * OrganizationsCard Component
 *
 * @param {OrganizationCardProps & { handleCloseSnackBar: (state: AlertsProps) => void; refreshOrganizations: () => void; }} props - Props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export default function OrganizationsCard(
  props: OrganizationCardProps & {
    handleCloseSnackBar: (state: AlertsProps) => void;
    refreshOrganizations: () => void;
  }
) {
  const Navigate = useNavigate();
  const { orgId, orgName, createdAt } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [openModal, setOpenModal] = useState(false);
  const { userRole } = useAppState();

  // Date Conversion
  let CDate = ConvertDate(createdAt);

  /**
   * Handles page navigation.
   *
   * @param {React.BaseSyntheticEvent} e - Event object.
   */
  const Navigation = (e: React.BaseSyntheticEvent) => {
    if (buttonRef?.current?.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      localStorage.removeItem("subAreaName");
      localStorage.setItem("OrganizationId", String(orgId));
      localStorage.setItem("Portfolioview", "0"); 
      Navigate(`/Organization/${orgId}/Organization-Details`);
    }
  };

   /**
   * Opens the delete confirmation dialog.
   */
  const openDialog = () => {
    setOpenModal(true);
  };

  /**
   * Closes the delete confirmation dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
  };

   /**
   * Handles the delete button click event.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} e - Event object.
   */
  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openDialog();
  };

  /**
   * Deletes the organization.
   */
  const deleteOrganization = async () => {
    try {
      const res = await _deleteOrganization(orgId);
      if (res) {
        props.refreshOrganizations();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

   /**
   * Handles the confirmation of the delete action.
   */
  const handleConfirm = () => {
    deleteOrganization();
    handleClose();
    props.handleCloseSnackBar({
      show: true,
      severity: "success",
      message: "Deleted Successfully !",
    });
  };

  return (
    <>
      <Card>
        <CardActionArea data-testid="card-action-area" onClick={Navigation}>
          <CardMedia
            component="img"
            height="200"
            image={projectImage1}
            alt="Project Image"
            data-testid="card-action-media"
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography
                  fontSize={"18px"}
                  fontWeight={600}
                  fontFamily={"Inter"}
                  data-testid="organizationName"
                >
                  {orgName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <br />
              </Grid>
              <Grid item xs={1.5} md={1.5}>
                <InsertInvitationIcon style={{ color: "#959393" }} />
              </Grid>
              <Grid item xs={5} md={8}>
                <Typography
                  fontFamily={"Inter"}
                  color="#959393"
                  data-testid="currentDate"
                >
                  {CDate}
                </Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                {userRole === UserRoles.SuperAdmin && (
                  <button 
                  onClick={handleDeleteClick} 
                  data-testid="org-delete" 
                  title="Delete Organization"
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer'
                  }}
                >
                  <DeleteOutlineIcon
                    style={{
                      color: "rgba(128, 148, 171, 1)",
                    }}
                  />
                </button>
                
                )}
              </Grid>
              <Grid item xs={1} md={1}>
                <StyledImg src={EyeOutlineIcon} alt="eye outline icon" />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <AlertDialog
        open={openModal}
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        organizationName={orgName}
        confirmationMessage={ConfirmationMessages.orgConfirmation}
      />
    </>
  );
}
