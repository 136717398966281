import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

// type
import { SpinnerProps } from "./data/Spinner.type";

/**
 * Spinner component displays a CircularProgress inside a Backdrop.
 *
 * @param {SpinnerProps} props - The props for the component.
 * @param {number} props.size - The size of the CircularProgress.
 * @param {boolean} props.open - Whether the Backdrop is open or not.
 * @returns {JSX.Element} The rendered Spinner component.
 */
const Spinner = ({ size, open }: SpinnerProps) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 110 }}
      open={open ?? true}
    >
      <CircularProgress size={size} color="primary" thickness={2} />
    </Backdrop>
  );
};

export default Spinner;
