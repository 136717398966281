import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
// theme
import { theme } from "./theme/Maintheme";
// Components
import Layout from "./components/AppLayout";

import { AppStateContext } from "context/appState.context";
import AlertNotification from "components/AlertNotification/AlertNotification";
import { useEffect, useMemo, useState } from "react";
import { AlertNotificationConfig } from "object-models/shared/alertNotification-config.model";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import HomePage from "pages/HomePage";
import OrganizationLayout from "pages/OrganizationLayout";
import Landing from "pages/LandingPage";
import { msalInstance } from "index";
import Glossary from "pages/Glossary/Glossary";
import { Utilities } from "utilities/utilities";
import SessionTimeoutModel from "components/SessionTimeout/SessionTimeout.model";
import authOperations from "configs/authService";

function App() {
  const notificationModelDefault: AlertNotificationConfig = {
    message: "",
    open: false,
    severity: undefined,
  };
  const [notificationConfig, setNotificationConfig] = useState(
    notificationModelDefault
  );
  const [clientId, setClientId] = useState(0);
  const [userRole, setUserRole] = useState("");
  const [orgName, setOrgName] = useState("");
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  useEffect(() => {
    if (accounts.length > 0) msalInstance.setActiveAccount(accounts[0]);
  }, [accounts]);

  const memoizedContextValue = useMemo(() => {
    return {
      notificationConfig,
      setNotificationConfig,
      clientId,
      setClientId,
      userRole,
      setUserRole,
      orgName,
      setOrgName
    };
  }, [
    notificationConfig,
    setNotificationConfig,
    clientId,
    setClientId,
    userRole,
    setUserRole,
    orgName,
    setOrgName
  ]);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const defaultExpireTime = 20000;
  const [timeLeft, setTimeLeft] = useState(defaultExpireTime);

  function CheckTokenExpiration() {
    setInterval(() => {
      if (
        Utilities.timeLeftInTokenExpiration() <= 5 &&
        Utilities.timeLeftInTokenExpiration() > 1
      ) {
        setTimeLeft(1);
      } else if (Utilities.timeLeftInTokenExpiration() <= 1) {
        setTimeLeft(0);
      }
    }, 1000 * 60);
  }

  function SetWarningModel() {
    if (timeLeft === 1) {
      setIsWarningModalOpen(true);
    } else if (timeLeft === 0) {
      handleLogout();
    }
  }

  useEffect(() => {
    CheckTokenExpiration();
  }, []);

  useEffect(() => {
    SetWarningModel();
  }, [timeLeft]);

  function refreshToken() {
    authOperations.getRefreshToken();
    setIsWarningModalOpen(false);
    setTimeLeft(defaultExpireTime);
  }

  return (
    <>
      {isWarningModalOpen && (
        <SessionTimeoutModel
          onContinueSession={() => refreshToken()}
          onLogOut={() => handleLogout()}
        />
      )}
      <ThemeProvider theme={theme}>
        <AppStateContext.Provider value={memoizedContextValue}>
          <AlertNotification />
          <Layout
            signOut={handleLogout}
            isAuthorized={isAuthenticated}
            account={accounts[0]}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <UnauthenticatedTemplate>
                    <Landing />
                  </UnauthenticatedTemplate>
                }
              />
              <Route
                path="/glossary"
                element={
                  <UnauthenticatedTemplate>
                    <Glossary />
                  </UnauthenticatedTemplate>
                }
              />

              <Route
                path="/Organizations"
                element={
                  <AuthenticatedTemplate>
                    <HomePage></HomePage>
                  </AuthenticatedTemplate>
                }
              />

              <Route
                id="10"
                path="/Organization/:OrganizationId/*"
                element={
                  <AuthenticatedTemplate>
                    <OrganizationLayout></OrganizationLayout>
                  </AuthenticatedTemplate>
                }
              />
              <Route
                id="11"
                path="/Organization/Portfolioview/*"
                element={
                  <AuthenticatedTemplate>
                    <OrganizationLayout></OrganizationLayout>
                  </AuthenticatedTemplate>
                }
              />
              
            </Routes>
          </Layout>
        </AppStateContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
