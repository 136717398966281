import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

export const GHGEmissionColumns: GridColDef[] = [
  {
    field: "year",
    headerClassName: "table-header",
    headerName: "Year",
    width: 200,
  },
  {
    field: "GHGEmissions",
    headerClassName: "table-header",
    headerName: "GHG Emissions",
    width: 250,
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontSize: 14, fontWeight: "light" }}>
        {params.row.GHGEmissions.toLocaleString("en-US")}
      </Typography>
    ),
  },
  {
    field: "units",
    headerClassName: "table-header",
    headerName: "Units",
    width: 250,
  },
];
