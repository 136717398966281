import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { MasterPropertyModel } from "object-models/shared/master-property.model";

interface GlossaryTableProps {
  glossaryList: Array<MasterPropertyModel>;
}

/**
 * GlossaryTable component
 * 
 * This component displays a table of glossary items. If the list is empty, it shows a message indicating no information is available.
 * 
 * @param {GlossaryTableProps} props - The props for the component.
 * @param {Array<MasterPropertyModel>} props.glossaryList - The list of glossary items to display in the table.
 * @returns {JSX.Element} A TableContainer with a table showing the glossary items.
 */
const GlossaryTable: React.FC<GlossaryTableProps> = ({ glossaryList }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Measure Category</TableCell>
            <TableCell>Measure Type</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {glossaryList.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} component="th" scope="row">
                No information available
              </TableCell>
            </TableRow>
          ) : (
            glossaryList.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.text}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GlossaryTable;
