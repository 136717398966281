import Stack from "@mui/material/Stack";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { DataTableProps } from "./DataTable.type";

/**
 * Component to display a message when no rows are found.
 * 
 * @returns {JSX.Element} The NoRowOverlay component.
 */
const NoRowOverLay = () => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    No rows found
  </Stack>
);


/**
 * DataTable component to display data in a table format with customizable 
 * columns and rows.
 * 
 * @param {Readonly<DataTableProps>} props - The properties for the DataTable component.
 * @returns {JSX.Element} The rendered DataTable component.
 */
export default function DataTable(props: Readonly<DataTableProps>) {
  const {
    rows,
    columns,
    selectedRows,
    setSelectedRows,
    onSelectionChange,
    disableCheckBox = false,
    hidePagination,
    selectionModel
    
  } = props;

  return (
    <div style={{ height: "75vh", width: "100%",display: "flex", flexDirection: "column" }}>
      <DataGrid
        rows={rows ?? []}
        columns={columns}
        getRowId={(row) => row.nzdmId}
        disableRowSelectionOnClick={true}
        onRowSelectionModelChange={(newSelectionModel) => {
          const filtered = rows?.filter((item: any) =>
            newSelectionModel.includes(item.nzdmId)
          );
          if (onSelectionChange && !disableCheckBox) {
            onSelectionChange(filtered ? [...filtered] : []);
          }
        }}
        checkboxSelection={!disableCheckBox}
        onRowClick={(row: GridRowParams) => {
          if (
            disableCheckBox &&
            selectedRows !== undefined &&
            setSelectedRows
          ) {
            if (
              selectedRows.length > 0 &&
              selectedRows.some((x) => x.nzdmId === row.id)
            ) {
              setSelectedRows(
                [...selectedRows].filter((x) => x.nzdmId !== row.id)
              );
            } else if (!selectedRows.some((x) => x.nzdmId === row.id)) {
              setSelectedRows([...selectedRows, row.row]);
            }
          }
        }}
        rowSelectionModel={
          selectionModel
        }
        hideFooterPagination={hidePagination} 
        columnBuffer={20}
      />
    </div>
  );
}
