import { CapexPlanResult } from "../Data/CapexPlan";
import React from "react";
import {
  TableCell,
  TableRow
} from "@mui/material";
import { styled } from "@mui/system";

interface CapexValueRowProps {
    years: number[];
    capexPlanResult: CapexPlanResult[]
  }
const StyledTableCell = styled(TableCell)({
    minWidth: 105,
    padding: "9px",
    textAlign: "right"
});

const CapexValueRow: React.FC<CapexValueRowProps> = ({years, capexPlanResult}: CapexValueRowProps): React.ReactElement => (
    <>
        {years.map((year) => (
            <TableRow key={`price-${year}`}>
                <StyledTableCell>{year}</StyledTableCell>
                {capexPlanResult.map((item) => {
                    const capexValue = item.capexModelCost.capexValues.find(
                        (cm) => cm.year == year
                    );
                    return (
                        <StyledTableCell key={`capex-${year}-${item.capexModelCost.orgId}`} >
                            { capexValue ? capexValue?.value?.toLocaleString('en-US', {minimumFractionDigits: 2}) : 0 }
                        </StyledTableCell>
                    );
                })}
            </TableRow>
        ))}
    </>
);

export default CapexValueRow;