import { Grid, Typography } from "@mui/material";

/**
 * GlossaryHeader component
 * 
 * This component renders a header for the glossary section, displaying the text "Measure Category" with specific styling.
 * 
 * @returns {JSX.Element} A Grid item containing a Typography element styled as a header.
 */
const GlossaryHeader = () => {
  return (
    <Grid item xs={12} border={0}>
      <Typography variant="h6" sx={{ fontSize: 20, fontWeight: 600 }} data-testid="measurecategory">
        Measure Category
      </Typography>
    </Grid>
  );
};

export default GlossaryHeader;
