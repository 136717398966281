import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

interface AlertDialogProps {
  open: boolean;
  openDialog: () => void;
  handleClose: () => void;
  handleConfirm: () => void;
  organizationName: string;
  confirmationMessage: string;
}

/**
 * AlertDialog component renders a confirmation dialog.
 * 
 * @param {AlertDialogProps} props - The properties for the AlertDialog component.
 * @returns {JSX.Element} The rendered AlertDialog component.
 */
export default function AlertDialog({
  open,
  openDialog,
  handleClose,
  handleConfirm,
  organizationName,
  confirmationMessage,
}: Readonly<AlertDialogProps>) {
   
  /**
   * Closes the dialog.
   */
  const handleCloseLocal = () => {
    handleClose();
  };

  /**
   * Confirms the action and closes the dialog.
   */
  const handleConfirmLocal = () => {
    handleConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseLocal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseLocal}
        sx={{
          position: "absolute",
          right: 2,
          mb: 2,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "20px",
            color: "rgba(12, 8, 8, 1)",
            mb: 2,
            fontWeight: "bold",
          }}
        >
          {`Are you sure you want to delete the ${organizationName}?`}
        </Typography>
        {confirmationMessage}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseLocal}>No, Cancel</Button>
        <Button
          onClick={handleConfirmLocal}
          variant="contained"
          data-testid="confirm-delete-button"
        >
          Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
