import apiHandler from "middlewares/apiHandler";
import { ScenarioModel } from "pages/Dashboards/ScenarioView/data/ScenarioModel";

/**
 * Fetches scenarios based on the organization's ID.
 * 
 * @param {number | undefined} orgId - The ID of the organization.
 * @returns {Promise<Array<ScenarioModel>>} - A promise that resolves to an array of ScenarioModel objects.
 */
export const _getScenarioByOrganizationsID = async (orgId: number | undefined) => {
  return apiHandler.get<Array<ScenarioModel>>(`Scenario/organization/${orgId}`);
};

/**
 * Adds a new scenario.
 * 
 * @param {Array<ScenarioModel>} model - The model representing the new scenario.
 * @returns {Promise<Array<ScenarioModel>>} - A promise that resolves to the added ScenarioModel objects.
 */
export const _addScenario = async (model: Array<ScenarioModel>) => {
  return apiHandler.post<Array<ScenarioModel>>("Scenario", model);
};