import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TitleDefinition from "components/TitleDefinition";

const DisplayItem = (props: {
  name: string;
  helptext?: string;
  isHelperTextRequired: boolean;
  value?: string | number;
  dataTestIdlabel: string;
  dataTestIdvalue: string;
  formatLocale?: boolean;
}) => {
  const value = () => {
    if (props.value) {
      if (typeof props.value === "string" && props.value.length > 0) {
        return props.value;
      } else if (typeof props.value === "number") {
        return props.formatLocale
          ? props.value.toLocaleString()
          : props.value.toString();
      }
    }
    return "-";
  };
  return (
    <Grid item xs={12} container direction="column" mb={2}>
      <Grid item container direction="row">
        <Typography
          variant="caption"
          fontWeight={500}
          data-testid={props.dataTestIdlabel}
          mr={0.5}
          sx={{ fontSize: 14 }}
        >
          {props.name}
        </Typography>
        {props.isHelperTextRequired ? (
          <TitleDefinition
            content={props.helptext ?? ""}
            placement="top"
          ></TitleDefinition>
        ) : null}
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          data-testid={props.dataTestIdvalue}
          minHeight={"28px"}
          fontWeight={600}
        >
          {value()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DisplayItem;
