import apiHandler from "middlewares/apiHandler";
import { PriceModel } from "pages/Settings/Prices/data/PriceModel";

/**
 * Fetches the price list for a specific organization.
 *
 * @param {number | undefined} orgId - The ID of the organization for which to fetch the price list. Can be undefined.
 * @returns {Promise<Array<PriceModel>>} A promise that resolves to an array of `PriceModel` objects.
 */
export const _getPriceList = async (orgId: number | undefined) => {
  return apiHandler.get<Array<PriceModel>>(`organization/${orgId}/priceList`);
};

/**
 * Adds a list of prices.
 *
 * @param {Array<PriceModel>} model - An array of `PriceModel` objects to be added.
 * @returns {Promise<Array<PriceModel>>} A promise that resolves to the added array of `PriceModel` objects.
 */
export const _addPriceList = async (model: Array<PriceModel>) => {
  return apiHandler.post<Array<PriceModel>>("pricelist", model);
};
