import { Grid } from "@mui/material";
import FormDialog from "components/Dialog/modal-popup";
import CreateUpdateReductionMeasures from "./CreateUpdateReductionMeasures";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import React from "react";
import ListReductionMeasures from "./ListReductionMeasures/ListReductionMeasures";

interface ReductionMeasureDesignProps {
  onAddMeasureClick: () => void;
  dialogOpen: boolean;
  hideDialogHandler: () => void;
  dialogTitle: string;
  isEdit: boolean;
  selectedReductionMeasureId: number;
  onEditMeasureClick: (selectedMeasureId: number) => void;
  setSelectedMeasure: (selectedMeasure: number) => void;
  handleCloseSnackBar: (state: AlertsProps) => void;
  isRefreshEditClose: () => void;
}

/**
 * CreateReductionMeasure component renders a dialog for creating or updating reduction measures.
 *
 * @component
 * @param {ReductionMeasureDesignProps} props - Component properties.
 * @returns {JSX.Element} The FormDialog component with CreateUpdateReductionMeasures inside.
 */
function CreateReductionMeasure(props: Readonly<ReductionMeasureDesignProps>) {
  return (
    <FormDialog
      dialogOpen={props.dialogOpen}
      hideDialogHandler={props.hideDialogHandler}
      dialogTitle={props.dialogTitle}
      isFullScreenAllowed={true}
      childComponent={
        <CreateUpdateReductionMeasures
          isEdit={props.isEdit}
          selectedReductionMeasureId={props.selectedReductionMeasureId}
          closeDialog={props.hideDialogHandler}
          handleCloseSnackBar={props.handleCloseSnackBar}
          isRefreshEditClose={props.isRefreshEditClose}
        ></CreateUpdateReductionMeasures>
      }
    ></FormDialog>
  );
}

/**
 * ReductionMeasureDesign component renders the main UI for managing reduction measures, including a dialog for creating/updating measures and a list of existing measures.
 *
 * @component
 * @param {ReductionMeasureDesignProps} props - Component properties.
 * @returns {JSX.Element} The main layout including CreateReductionMeasure dialog and ListReductionMeasures.
 */
export default function ReductionMeasureDesign(
  props: Readonly<ReductionMeasureDesignProps>
) {
  return (
    <React.Fragment>
      {props.dialogOpen && CreateReductionMeasure(props)}
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          {
            <ListReductionMeasures
              onAddMeasureClick={props.onAddMeasureClick}
              onEditMeasureClick={props.onEditMeasureClick}
              refreshView={props.dialogOpen === false}
            ></ListReductionMeasures>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
