import {
  ReductionMeasureListModel,
  ReductionMeasureModel,
} from "object-models/ReductionMeasures";

import apiHandler from "../apiHandler";

export const _getReductionMeasureList = async (organizationId: number) => {
  return apiHandler
    .get<Array<ReductionMeasureListModel>>(
      "organization/" + organizationId + "/reductionMeasures"
    )
    .then((res) => {
      res.forEach((r) => (r.id = r.reductionMeasureId));
      return res;
    });
};

export const _addReductionMeasure = async (measure: ReductionMeasureModel) => {
  return apiHandler.post<ReductionMeasureModel>("reductionmeasure", measure);
};
export const _updateReductionMeasure = async (
  measure: ReductionMeasureModel
) => {
  return apiHandler.put<ReductionMeasureModel>(
    "updatereductionmeasure",
    measure
  );
};

export const _getReductionMeasure = async (byId: number) => {
  return apiHandler.get<ReductionMeasureModel>("reductionMeasures/" + byId);
};

export const _deleteReductionMeasure = async (reductionMeasureId: number) => {
  return apiHandler.delete("reductionMeasures/" + reductionMeasureId);
};
