// EmptyState.tsx
import React from "react";
import { Grid, Typography } from "@mui/material";

interface EmptyStateProps {
  /**
   * Function to handle redirection when the button is clicked.
   */
  handleRedirect: () => void;
}

/**
 * EmptyState component displays a message indicating that at least one measure
 * needs to be created to view the scenarios and provides a button to create a new measure.
 *
 * @component
 * @param {EmptyStateProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const EmptyState: React.FC<EmptyStateProps> = ({ handleRedirect }) => {
  return (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        id="test-typography-01"
        component="div"
        color="gray"
        style={{ fontWeight: "bold" }}
        sx={{ mt: 20 }}
      >
        You need to create at least one measure to view the scenarios.
        <br />
        To create a new measure{" "}
        <button
          onClick={handleRedirect}
          data-testid="emptyClickEvent"
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              handleRedirect();
            }
          }}
          style={{
            color: "blue",
            textDecoration: "underline",
            background: "none",
            border: "none",
            padding: 0,
            cursor: "pointer",
            fontSize: "inherit",
            fontFamily: "inherit",
            fontWeight: "bold",
          }}
        >
          click here
        </button>
      </Typography>
    </Grid>
  );
};

export default EmptyState;
