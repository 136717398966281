export class NumericValidator {
  allowNumericValue(value: string, allowNegative?: boolean) {
    if (+value && allowNegative === true) {
      return value as unknown as number;
    }
    if (+value >= 0) {
      return value as unknown as number;
    } else {
      return value
        .split("")
        .filter((item) => {
          if (
            (item.charCodeAt(0) >= 48 && item.charCodeAt(0) <= 57) ||
            item.charCodeAt(0) === 46 ||
            (allowNegative && item.charCodeAt(0) === 45)
          ) {
            return true;
          }
          return false;
        })
        .join("");
    }
  }
}

const numericValidator = new NumericValidator();
export default numericValidator;
