export const scopesCoveredOptions = [
    { id: 0, label: "Scope 1", value: "Scope 1" },
    { id: 1, label: "Scope 2", value: "Scope 2" },
    { id: 2, label: "Scope 3", value: "Scope 3" },
  ];

  export interface DataItem {
    name: string;
    value: number;
  }  

  export interface EmissionData {
    [key: string]: number;
  }