import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ProjectRegisterMetrics } from "constants/constant";

import {
  metrics,
  ProjectRegisterOptions,
} from "pages/Dashboards/ProjectRegister/data/ProjectRegisterData";

import ProjectMetrics from "pages/Dashboards/ProjectRegister/ProjectMetrics";
import { useAppState } from "context/appState.context";
import { _getOrganizations } from "middlewares/OrganizationApi/organization";
import { ProjectRegisterFilter } from "../Data/ProjectRegisterFilter";
import { _getMultipleOrgProjectRegisterDetails } from "middlewares/DashboardApi/portfolioView";
import { ProjectRegisterModel } from "../Data/ProjectRegisterModel";
import ProjectRegisterTable from "./ProjectRegisterTable";
import Spinner from "components/Spinner";
import { MIBScopeData } from "pages/Dashboards/ProjectRegister/data/MIBScopeData";
import MeasureImpactsbyScope from "pages/Dashboards/ProjectRegister/MeasureImpactsbyScope";
import { FilterParam } from "../Data/FilterParam";
import FilterSection from "./FilterSection";
import MarginalAbatementCostCurve from "pages/Dashboards/ProjectRegister/MarginalAbatementCostCurve";
import { MACgraphModel } from "pages/Dashboards/ProjectRegister/data/MACgraphModel";
import { TLFMetricsData } from "pages/Dashboards/ProjectRegister/data/TLFMetricsData";
import { generateOrgLevelData } from "pages/Dashboards/ProjectRegister/data/metricsUtils";
import TimelineAndFinancialMetrics from "pages/Dashboards/ProjectRegister/TimelineAndFinancialMetrics";

const PortfolioProjectRegister = () => {
  const { userRole } = useAppState();

  const [selectedOption, setSelectedOption] = useState(
    ProjectRegisterMetrics.MarginalAbatementCostCurve
  );
  const [loading, setLoading] = useState(false);

  const [organizationOptions, setOrganizationOptions] = useState<
    { id: number; label: string; value: string }[]
  >([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([
    -1,
  ]);
  const [metricsData, setMetricsData] = useState(metrics);
  const [projectRegisterFilter, setProjectRegisterFilter] =
    React.useState<ProjectRegisterFilter>({
      param: [],
      baseYear: null,
      dashboardSection: selectedOption,
    });

  const [projectRegisterModelList, setProjectRegisterModelList] = useState<
    Array<ProjectRegisterModel>
  >([]);
  const [mibScopeData, setMibScopeData] = useState<MIBScopeData[]>([]);
  const [isMacDataLoaded, setIsMacDataLoaded] = useState(false);
  const [macData, setMacData] = useState<MACgraphModel[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [param, setParam] = useState<FilterParam[]>([]);
  const isInitialRender = useRef(true);
  const [tlfMetricsData, setTlfMetricsData] = useState<TLFMetricsData[]>([]);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setProjectRegisterFilter((prevFilter) => ({
      ...prevFilter,
      dashboardSection: selectedValue, // Update the dashboardSection with the selected option
    }));
  };
  
  const handleOrganizationChange = (selectedOptions: number[]) => {
    setSelectedOrganizations(selectedOptions);
  };

  const handleScenarioChange = (orgId: number, selectedScenario: string) => {
    setProjectRegisterFilter((prevFilter) => {
      const updatedParam = prevFilter.param.map((item) => {
        if (item.orgId === orgId) {
          return { ...item, scenarioSection: selectedScenario };
        }
        return item;
      });
      
      const orgExists = updatedParam.some((item) => item.orgId === orgId);
      let updatedParamState = updatedParam;
      if (!orgExists) {
        updatedParamState = param.map((item) => {
          if (item.orgId === orgId) {
            // Update the scenarioSection for the matching orgId in the state variable
            return { ...item, scenarioSection: selectedScenario };
          }
          return item;
        });
      }

      return {
        ...prevFilter,
        param: orgExists ? updatedParam : updatedParamState,
      };
    });
  };

  function getEssentialOrganizationDetails() {
    _getOrganizations(userRole)
      .then((res) => {
        if (res) {
          const formattedOptions = res.map((org) => ({
            id: org.orgId,
            label: org.orgName,
            value: org.orgId.toString(),
          }));
          const selectAllOption = {
            id: 0,
            label: "Select All",
            value: "0",
          };
          setOrganizationOptions([selectAllOption, ...formattedOptions]);
          const firstFiveOrgIds = res.slice(0, 5).map((org) => org.orgId);
          setSelectedOrganizations(firstFiveOrgIds);
          const param: FilterParam[] = firstFiveOrgIds.map(
            (item: any) => ({
              orgId: item,
              scenarioSection: "Scenario A",
            })
          );
          
          setProjectRegisterFilter((prevFilter) => ({
            ...prevFilter,
            param: param,
          }));
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        setLoading(false);
      });
  }

  function getMultipleOrgProjectRegisterDetails() {
    setLoading(true);

    _getMultipleOrgProjectRegisterDetails(projectRegisterFilter)
      .then(handleResponse)
      .catch(handleError);
  }

  function handleResponse(res: any) {
    if (res) {
      const { projectRegisterModelList, dashboardMetric } = res;
      updateMetricsData(dashboardMetric);
      updateProjectRegisterModelList(projectRegisterModelList);

      if (isInitialRender.current) {
        const param: FilterParam[] = projectRegisterModelList.map(
          (item: any) => ({
            orgId: item.orgId,
            scenarioSection: item.scenarios.length > 0 ? item.scenarios[0] : "",
          })
        );
        setParam(param);
        isInitialRender.current = false; // Mark as not the first render anymore
      }
      if (
        selectedOption === ProjectRegisterMetrics.MarginalAbatementCostCurve
      ) {
        setMacData(res.orgLevelMACGraphModelList);
        setIsMacDataLoaded(true);
      }
      if (
        selectedOption == ProjectRegisterMetrics.TimelineandFinancialMetrics
      ) {
        const data = generateOrgLevelData(res);
        setTlfMetricsData(data);
        setIsDataLoaded(true);
      }
      if (selectedOption === ProjectRegisterMetrics.MeasureImpactsbyScope) {
        const data: MIBScopeData[] = res.impactByScopeModelList.map(
          (item: any, index: any) => {
            const mibScopeData: MIBScopeData = {
              label: item.orgName,
              value: item.emissionReduction,
            };
            return mibScopeData;
          }
        );
        setMibScopeData(data);
      }
    }
    setLoading(false);
  }

  function handleError(err: any) {
    console.error("Error:", err);
    setLoading(false);
  }

  function updateMetricsData(dashboardMetric: any) {
    setMetricsData((prevMetricsData) =>
      prevMetricsData.map((metric) => ({
        ...metric,
        value: dashboardMetric[metric.key],
      }))
    );
  }

  function updateProjectRegisterModelList(projectRegisterModelList: any[]) {
    const modifiedRes = projectRegisterModelList.map((record, index) => ({
      ...record,
      rowId: `${index + 1}`,
    }));
    setProjectRegisterModelList(modifiedRes);
  }

  const handleSearch = async () => {
    const filteredParam = param.filter((item) =>
      selectedOrganizations.includes(Number(item.orgId))
    );
    setProjectRegisterFilter((prevFilter) => ({
      ...prevFilter,
      param: filteredParam,
    }));
  };

  useEffect(() => {
    if (selectedOrganizations.length > 0 && projectRegisterFilter.param.length > 0) {
      getMultipleOrgProjectRegisterDetails();
    }
  }, [projectRegisterFilter.param,selectedOption]);

  useEffect(() => {
    getEssentialOrganizationDetails();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            color: "#0C0808",
            padding: "8px",
          }}
          data-testid="netZeroCarbonizationLabel"
        >
          Net Zero Carbonization Model
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            color: "#0C0808",
            marginLeft: "6px",
          }}
          data-testid="marginalAbatementCostCurveLabel"
        >
          {selectedOption}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: "right" }}>
        <FormControl fullWidth>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ width: "320px" }}
            id="projectRegisterOptionsDrodpdown"
            data-testid="projectRegisterOptionsDrodpdown"
          >
            {ProjectRegisterOptions.map((option) => (
              <MenuItem
                key={option.id}
                value={option.value}
                sx={{ textAlign: "left" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <FilterSection
        organizationOptions={organizationOptions}
        selectedOrganizations={selectedOrganizations}
        onOrganizationChange={handleOrganizationChange}
        onSearch={handleSearch}
      />
      {loading ? (
        <Spinner size={80} data-testid="spinner" />
      ) : (
        <>
          <Grid container spacing={2}>
            <ProjectMetrics metrics={metricsData}></ProjectMetrics>
          </Grid>
          <Grid style={{ width: "100%" }}>
          {selectedOption ===
              ProjectRegisterMetrics.MarginalAbatementCostCurve &&
              isMacDataLoaded && <MarginalAbatementCostCurve data={macData} labelKey="orgName" />}
          {selectedOption ===
            ProjectRegisterMetrics.TimelineandFinancialMetrics &&
            isDataLoaded && (
              <TimelineAndFinancialMetrics
                data={tlfMetricsData}
                width={1000}
                height={800}
              />
            )}
          {selectedOption ===
            ProjectRegisterMetrics.MeasureImpactsbyScope && (
            <MeasureImpactsbyScope data={mibScopeData} />
          )}
          </Grid>
          <Grid style={{ width: "100%" }}>
            <ProjectRegisterTable
              projectRegisterModel={projectRegisterModelList}
              onScenarioChange={handleScenarioChange}
            ></ProjectRegisterTable>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PortfolioProjectRegister;
