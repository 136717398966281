import calendar from "../../../../assets/Images/dashboard/calendar.png";
import business from "../../../../assets/Images/dashboard/business.png";
import capitalexpenditure from "../../../../assets/Images/dashboard/capital-expenditure.png";
import pollution from "../../../../assets/Images/dashboard/pollution.png";
import upArrow from "../../../../assets/Images/dashboard/up-arrow.png";


// Base years array
export const baseYears = Array.from({ length: 37 }, (_, index) => 2015 + index);

// Target years array
export const targetYears = Array.from({ length: 28 }, (_, index) => 2024 + index);

// Scopes covered options array
export const scopesCoveredOptions = [
  { id: 1, label: "Scope 1", value: "Scope 1" },
  { id: 2, label: "Scope 2", value: "Scope 2" },
  { id: 3, label: "Scope 3", value: "Scope 3" },
];

export const ProjectRegisterOptions = [
  {
    id: 0,
    label: "Marginal Abatement Cost",
    value: "Marginal Abatement Cost",
  },
  {
    id: 1,
    label: "Timeline and Financial Metrics",
    value: "Timeline and Financial Metrics",
  },
  {
    id: 2,
    label: "Measure Impacts by Scope",
    value: "Measure Impacts by Scope",
  }
];

export const metrics = [
  { id: 0, label: "Emission Reduction (MT CO₂e)",key:'emissionReduction', value: 0,src:pollution },
  { id: 1, label: "Annual NPV ($)",key:'annualNPV', value: 0,src:calendar },
  { id: 2, label: "CAPEX Investment($)",key:'capexInvestment', value: 0,src:capitalexpenditure },
  { id: 3, label: "OPEX Investment($)", key:'opexInvestment',value: 0,src:business },
  { id: 4, label: "Marginal Abatement Cost($/MT CO₂e)",key:'mac', value: 0 ,src:upArrow},
];

// Scopes covered options array
export const businessUnitOptions = [
  { id: 1, label: "Unit 1", value: "01" },
  { id: 2, label: "Unit 2", value: "02" },
  { id: 3, label: "Unit 3", value: "03" },
  { id: 4, label: "Unit 4", value: "04" },
  { id: 5, label: "Unit 5", value: "05" },
];

export const scenarioOptions = [
  { id: 1, label: "Scenario A", value: "Scenario A" },
  { id: 2, label: "Scenario B", value: "Scenario B" },
  { id: 3, label: "Scenario C", value: "Scenario C" },
  { id: 4, label: "Scenario D", value: "Scenario D" },
  { id: 5, label: "Scenario E", value: "Scenario E" },
];