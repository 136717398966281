import moment from 'moment';
import appStorage from "./app-storage";

export class Utilities {

    static isTokenExpired(): boolean {
      const timeLeftInExpiration = this.timeLeftInTokenExpiration();
      return timeLeftInExpiration <= 0;
    }

    static timeLeftInTokenExpiration(): number {
      const expireOn = appStorage.getExpirationTime();
      const timeNow = moment(new Date().toISOString());
      const expTime = moment(expireOn);
      const duration = moment.duration(expTime.diff(timeNow));
      return duration.asMinutes();
    }

    static redirectToLogout() {
      window.location.href = "/";
    }
  }