import { CapexPlanResult } from "../Data/CapexPlan";
import React from "react";
import {
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";

interface CapexSelectScenarioRow {
  capexPlanResult: CapexPlanResult[];
  onChange: (value: string | null | number, orgId: number | null) => void;
}

const CustomTableCell = styled(TableCell)({
  padding: "9px", // Adjust the padding as needed
});

const CapexSelectScenarioRow: React.FC<CapexSelectScenarioRow> = ({
  capexPlanResult,
  onChange,
}: CapexSelectScenarioRow): React.ReactElement => (
  <TableRow>
    <CustomTableCell>Scenario</CustomTableCell>
    {capexPlanResult.map((item) => (
      <CustomTableCell
        key={`capex-${item.capexModelCost.orgName}-${item.capexModelCost.scenarioName}`}
      >
        <FormControl margin="normal">
          <Select
            id={item.capexModelCost.scenarioName}
            name={item.capexModelCost.scenarioName}
            value={item.capexModelCost.scenarioName}
            sx={{ width: "140px" }}
            data-testid="scenarioChangeId"
            onChange={(e) =>
              onChange(e.target.value, item.capexModelCost.orgId)
            }
          >
            <MenuItem value="0">Select Scenario</MenuItem>
            {item.capexModelCost.listOfScenario.map((option, index) => (
              <MenuItem key={option + index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CustomTableCell>
    ))}
  </TableRow>
);

export default CapexSelectScenarioRow;
