import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { IconButton, Slide, SlideProps } from "@mui/material";
import { AlertNotificationConfig } from "object-models/shared/alertNotification-config.model";
import { useAppState } from "context/appState.context";
import CloseIcon from '@mui/icons-material/Close';

type TransitionProps = Omit<SlideProps, "direction">;
/**
 * Transition component for the Snackbar, sliding down.
 *
 * @param {TransitionProps} props - The properties for the transition.
 * @returns {JSX.Element} The transition component.
 */
function TransitionDown(props: Readonly<TransitionProps>) {
  return <Slide {...props} direction="down" />;
}

/**
 * AlertNotification component displays a Snackbar with an Alert message.
 *
 * @returns {JSX.Element} The rendered AlertNotification component.
 */
export default function AlertNotification() {
  const { notificationConfig, setNotificationConfig } = useAppState();

    /**
   * Handles closing the Snackbar.
   *
   * @param {React.SyntheticEvent | Event} [event] - The event triggering the close.
   * @param {string} [reason] - The reason for closing.
   */
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    const reset: AlertNotificationConfig = {
      message: "",
      open: false,
      severity: undefined,
    };
    setNotificationConfig(reset);
  };

  /**
   * Sets the auto-hide duration based on the severity of the notification.
   *
   * @returns {number} The auto-hide duration in milliseconds.
   */
  const setAutoHideDuration = () => {
    if (
      notificationConfig.severity === "error" ||
      notificationConfig.severity === "warning"
    ) {
      return 30000; //30 seconds
    } else return 5000; //5 seconds
  };

  return (
    <Snackbar
      open={notificationConfig.open}
      autoHideDuration={setAutoHideDuration()}
      onClose={handleClose}
      TransitionComponent={TransitionDown}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={notificationConfig.severity}
        sx={{ width: "100%" }}
        data-testid="alert-notification"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
            sx={{ ml: -2 }} 
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {notificationConfig.message}
      </Alert>

    </Snackbar>
  );
}
