// CustomDataGrid.tsx

import React from 'react';
import { DataGrid, GridColDef,GridRowIdGetter } from '@mui/x-data-grid';

interface CustomDataGridProps {
  rows: any[];
  columns: GridColDef[];
  pageSizeOptions?: number[];
  getRowId?: GridRowIdGetter<any>;
}

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  rows,
  columns,
  pageSizeOptions = [5, 10, 15, 20],
  getRowId,
}) => (
  <DataGrid
    rows={rows}
    columns={columns}
    pagination
    pageSizeOptions={pageSizeOptions}
    getRowId={getRowId}
    disableRowSelectionOnClick
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 10 },
      },
    }}
    autoHeight
    columnBuffer={20}
    sx={{
      "& .MuiDataGrid-columnHeaders": {
        minHeight: "56px !important",
        maxHeight: "fit-content !important",
      },
      "& .MuiDataGrid-columnHeader": {
        height: "auto !important",
        maxHeight: "none !important",
        lineHeight: "normal !important",
        padding: "8px !important",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal !important",
        lineHeight: "1.2 !important",
      },
    }}
  />
);

export default CustomDataGrid;
