import { useMemo } from "react";
import * as d3 from "d3";

const MARGIN = { top: 20, right: 20, bottom: 20, left: 20 };
const BAR_PADDING = 0.2;

/**
 * Props interface for the Bar Chart component.
 */
type BarplotProps = {
  width: number;
  height: number;
  categoryHeight: number;
  data: { name: string; value: number }[];
};

/**
 * Component for displaying a horizontal bar chart of emissions by scope category.
 */
export default function EmissionByScopeCategoryBarChart({
  width,
  height,
  data,
  categoryHeight
}: Readonly<BarplotProps>) {
  // bounds = area inside the graph axis = calculated by substracting the margins
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  const categoryLength = data.filter(item => item.value > 0).length;
  const adjustedCategoryLength = (categoryLength === 1) ? categoryLength + 0.5 : categoryLength;
  // Y axis is for groups since the barplot is horizontal
  data.sort((a, b) => b.value - a.value);
  const groups = data.map((d) => d.name);
  const yScale = useMemo(() => {
    return d3
      .scaleBand()
      .domain(groups)
      .range([0, boundsHeight])
      .padding(BAR_PADDING);
  }, [data, height]);

  // X axis
  const xScale = useMemo(() => {
    const [, max] = d3.extent(data.map((d) => d.value));
    return d3
      .scaleLinear()
      .domain([0, max ?? 10])
      .range([0, boundsWidth]);
  }, [data, width]);

  // Build the shapes
  const allShapes = data.map((d, i) => {
    const y = yScale(d.name);
    if (y === undefined) {
      return null;
    }
    if (d.value > 0) {
      return (
        <g key={d.name}>
          <rect
            x={xScale(0)}
            y={yScale(d.name)}
            width={xScale(d.value)}
            height={yScale.bandwidth()}
            opacity={0.7}
            stroke="#e39696"
            fill="#e39696"
            fillOpacity={0.7}
            strokeWidth={1}
            rx={1}
          />
          <text
            x={width - width / 10}
            y={y + yScale.bandwidth() / 2}
            textAnchor="end"
            alignmentBaseline="central"
            fontSize={12}
            // opacity={xScale(d.value) > 90 ? 1 : 0} // hide label if bar is not wide enough
          >
            {d.value.toLocaleString("en-US")}
          </text>
          <text
            x={xScale(0)}
            y={y + yScale.bandwidth() / 2}
            textAnchor="start"
            alignmentBaseline="central"
            fontSize={12}
          >
            {d.name}
          </text>
        </g>
      );
    }
  });

  const grid = xScale
    .ticks(5)
    .slice(1)
    .map((value, i) => {
      if (value > 0) {
        return (
          <g key={value.toString()}>
            <line
              x1={xScale(value)}
              x2={xScale(value)}
              y1={0}
              y2={boundsHeight}
              stroke="#808080"
              opacity={0.2}
            />
            <text
              x={xScale(value)}
              y={boundsHeight + 10}
              textAnchor="middle"
              alignmentBaseline="central"
              fontSize={9}
              stroke="#808080"
              opacity={0.8}
            >
              {value.toLocaleString("en-US")}
            </text>
          </g>
        );
      }
    });

  const title = (
    <text
      x={width / 2}
      y={5}
      textAnchor="middle"
      alignmentBaseline="central"
      fontSize={14}
      stroke="#808080"
    >
      Scope 3 Emissions by category
    </text>
  );
  return (
    <div>
      <svg width={width} height={categoryHeight*adjustedCategoryLength}>
        {title}
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {grid}
          {allShapes}
        </g>
      </svg>
    </div>
  );
}
