// src/App.js
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from "react";
import "../../App.css";
import CallToAction from "./cta/cta";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "configs/MsalConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
const Landing = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  
  const doSignIn = () => {
        instance.loginRedirect(loginRequest).catch(error => {         
          console.error(`error during authentication: ${error}`)
      })
   }

const navigate = useNavigate();
const redirect = async () => {
  navigate("/organizations");  
}
  useEffect(() => {
    if (isAuthenticated) redirect();
  }, []);
  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid xs={12}>
            <CallToAction doSignIn={doSignIn}></CallToAction>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Landing;