import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import GlossaryHeader from "./GlossaryHeader";
import GlossaryTable from "./GlossaryTable";
import masterPropertyApi from "middlewares/MasterProperty";
import { MasterPropertyModel } from "object-models/shared/master-property.model";

/**
 * Glossary component
 * 
 * This component fetches and displays a list of glossary items. It includes a header and a table.
 * 
 * @returns {JSX.Element} A container Grid with a GlossaryHeader and GlossaryTable.
 */
const Glossary = () => {
  const [glossaryList, setGlossaryList] = useState<Array<MasterPropertyModel>>([]);

  useEffect(() => {
    getMasterPropertyListForGlossary();
  }, []);

   /**
   * Fetches the glossary list from the API and updates the state.
   * 
   * @async
   * @function getMasterPropertyListForGlossary
   * @returns {Promise<void>} A promise that resolves when the glossary list is fetched and set in state.
   */
  const getMasterPropertyListForGlossary = async () => {
    try {
      const res = await masterPropertyApi.getMasterPropertyListForGlossary();
      setGlossaryList(res);
    } catch (error) {
      console.error("Error fetching glossary:", error);
    }
  };

  return (
    <Grid container>
      <GlossaryHeader />
      <GlossaryTable glossaryList={glossaryList} />
    </Grid>
  );
};

export default Glossary;
