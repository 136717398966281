import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { PriceModel } from "./data/PriceModel";
import { styled } from "@mui/system";

interface UnitsRowProps {
  priceData: PriceModel[];
}

const CustomTableCell = styled(TableCell)({
  padding: '9px', // Adjust the padding as needed
});

/**
 * Component that renders a table row with unit information for each `PriceModel` item.
 *
 * @param {UnitsRowProps} props - The props for the component.
 * @param {PriceModel[]} props.priceData - The array of `PriceModel` objects containing the data to display.
 * @returns {React.ReactElement} - A `TableRow` element containing table cells with unit information.
 */
const UnitsRow: React.FC<UnitsRowProps> = ({
  priceData,
}: UnitsRowProps): React.ReactElement => (
  <TableRow>
    <CustomTableCell>Units</CustomTableCell>
    {priceData.map((item) => (
      <CustomTableCell key={item.id}>
         <Typography variant="body2">
          {item.energyUnits +
            " " +
            (item.region ? `(${item.region})` : "") +
            " " +
            item.energyType}
        </Typography>
        
      </CustomTableCell>
    ))}
  </TableRow>
);

export default UnitsRow;
