import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// Components
import { DrawerHeader } from '../NavigationBar';
import { Drawer } from './Drawer';
import PageNavigation from 'pages/OrganizationLayout/PageNavigation';

/**
 * Component for the left navigation bar with a collapsible drawer.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function LeftNavigationBar() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  /**
   * Function to open the drawer.
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * Function to close the drawer.
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  return (
    <Drawer variant="permanent" open={open} data-testid="drawer">
      {!open && <IconButton
        color="primary"
        data-testid="menu-icon-button"
        onClick={handleDrawerOpen}
        sx={{
          margin: "10px",
          ...(open && { display: 'none' }) as any,
        }}
      >
        <MenuIcon />
      </IconButton>}
      {open && <DrawerHeader>
        <IconButton onClick={handleDrawerClose}  data-testid="menu-close-icon-button">
          {theme.direction === 'rtl' ?
            <IconButton
              color="primary"
              onClick={handleDrawerOpen}
              sx={{
                ...(open && { display: 'none' }),
              }}
            >
              <ChevronRightIcon />
            </IconButton> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>}
      <Divider />

      {/* Page Navigation Dynamic List Component */}
      <PageNavigation open={open}/>
    </Drawer>
  );
}
