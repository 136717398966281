import { Grid } from "@mui/material";
import { OrganizationModel } from "object-models/organization-model";
import CategoryInfo from "./CategoryInfo";

/**
 * Displays detailed category information based on the provided organization details.
 * 
 * @component
 * @param {orgDetails} props - The properties for the CategoryInfoDetails component.
 * @returns {JSX.Element} The rendered CategoryInfoDetails component.
 */
const CategoryInfoDetails = ({
  orgDetails,
}: {
  orgDetails: OrganizationModel | null;
}) => {
  if (orgDetails === null) {
    return <div>No data available.</div>;
  }
  const categories = [
    {
      category: "Category 1",
      label: "Purchased Goods & Services",
      value: orgDetails.purchasedGoodsAndServices,
    },
    {
      category: "Category 2",
      label: "Capital Goods",
      value: orgDetails.capitalGoods,
    },
    {
      category: "Category 3",
      label: "Fuel- and Energy-Related Activities",
      value: orgDetails.fuelandEnergyRelatedActivities,
    },
    {
      category: "Category 4",
      label: "Upstream Transportation & Distribution",
      value: orgDetails.upstreamTransportationAndDistribution,
    },
    {
      category: "Category 5",
      label: "Waste Generated in Operations",
      value: orgDetails.wasteGeneratedinOperations,
    },
    {
      category: "Category 6",
      label: "Business Travel",
      value: orgDetails.businessTravel,
    },
    {
      category: "Category 7",
      label: "Employee Commuting",
      value: orgDetails.employeeCommuting,
    },
    {
      category: "Category 8",
      label: "Upstream Leased Assets",
      value: orgDetails.upstreamLeasedAssets,
    },
    {
      category: "Category 9",
      label: "Downstream Transportation & Distribution",
      value: orgDetails.downstreamTransportationAndDistribution,
    },
    {
      category: "Category 10",
      label: "Processing of Sold Products",
      value: orgDetails.processingofSoldProducts,
    },
    {
      category: "Category 11",
      label: "Use of Sold Products",
      value: orgDetails.useofSoldProducts,
    },
    {
      category: "Category 12",
      label: "End-of-Life Treatment of Sold Products",
      value: orgDetails.endofLifeTreatmentofSoldProducts,
    },
    {
      category: "Category 13",
      label: "Downstream Leased Assets",
      value: orgDetails.downstreamLeasedAssets,
    },
    {
      category: "Category 14",
      label: "Franchises",
      value: orgDetails.franchises,
    },
    {
      category: "Category 15",
      label: "Investments",
      value: orgDetails.investments,
    },
    {
      category: "Category 16",
      label: "Other Upstream",
      value: orgDetails.otherUpstream,
    },
    {
      category: "Category 17",
      label: "Other Downstream",
      value: orgDetails.otherDownstream,
    },
  ];
  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12}>
        <Grid container spacing={2} sx={{ mt: 0, mb: 5 }}>
          {categories.map((categoryData, index) => (
            <CategoryInfo
              key={categoryData.category}
              category={categoryData.category}
              value={Number(categoryData.value).toLocaleString("en-US")}
              label={categoryData.label}
            />
          ))}
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}></Grid>
      </Grid>
    </Grid>
  );
};

export default CategoryInfoDetails;
