import Grid from "@mui/material/Grid";

// Components
import DisplayOrganizations from "./DisplayOrganizations";
import { useAuth } from "hooks/auth.hook";
import { loginRequest } from "configs/MsalConfig";
import Spinner from "components/Spinner";

/**
 * HomePage Component
 *
 * This component renders the home page of the application. It checks if the user is authenticated
 * and displays the organizations or a spinner based on the authentication state.
 *
 * @returns {JSX.Element} The rendered HomePage component.
 */
const HomePage = () => {
  const accessToken = useAuth(loginRequest);
  // Remove Highlights
  localStorage.removeItem("Highlights");
  localStorage.setItem("HighlightsMenu", "Organization Details");
  return (
    <Grid container spacing={2}>
      {accessToken ? (
        <DisplayOrganizations />
      ) : (
        <Spinner size={80} data-testid="spinner" />
      )}
    </Grid>
  );
};

export default HomePage;
