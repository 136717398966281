import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import Blurb from "components/Blurb/Blurb";
import SingleDropDown from "components/Dropdowns/SingleDropDown";
import SingleDropdownSearch from "components/Dropdowns/SingleDropdownSearch";
import TitleDefinition from "components/TitleDefinition";
import { Currency, TitleDefinitionConstants } from "constants/constant";
import { OrganizationModel } from "object-models/organization-model";
import { useForm } from "react-hook-form";
import { CreateOrganizationProps } from "./data/CreateOrganizationProps";

const formatValueForDisplay = (value: number | undefined): string => {
  return value && value > 0 
    ? value.toLocaleString("en-US")
    : "";
};

/**
 * Component for creating or updating design an organization.
 */
export default function CreateOrganizationDesign(
  props: Readonly<CreateOrganizationProps>
) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
  } = useForm<OrganizationModel>();
  

  return (
    <form
      onSubmit={handleSubmit(() => {
        props.onSave();
      })}
    >
      <Box sx={{ m: 5 }}>
        <Grid container spacing={2}>
          {props.organization && (
            <Grid item xs={6}>
              <Typography variant="body1" data-testid={"OrganizationNameLabel"}>
                Organization Name
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>

              <TextField
                required
                {...register("orgName", {
                  maxLength: {
                    value: 50,
                    message: "Organization Name is too long",
                  },
                  validate: {
                    orgnameValidate: async (value) => {
                      const isExist = await props.isOrganizationExist(
                        value ?? ""
                      );
                      return !isExist || "Organization name already exists.";
                    },
                  },
                })}
                id="orgName"
                inputProps={{ "data-testid": "orgName" }}
                fullWidth
                margin="normal"
                size="small"
                name={"orgName"}
                value={props.organization.orgName}
                onChange={(e) => {
                  clearErrors("orgName");
                  props.fieldChangeHandler(e);
                }}
                error={!!errors.orgName}
                helperText={errors.orgName?.message}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"IndustryLabel"}
                sx={{ mb: 2 }}
              >
                Industry
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={TitleDefinitionConstants.OrganizationDetails.Industry}
                placement="auto"
              />
            </Stack>
            <SingleDropdownSearch
              required
              searchField={"value"}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              list={props.industryList}
              value={props.organization.industry}
              name={"industry"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"currencyLabel"}
                sx={{ mb: 2 }}
              >
                Currency
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={TitleDefinitionConstants.OrganizationDetails.Currency}
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              required
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              list={Currency.currency}
              value={props.organization.currency}
              name={"currency"}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"AnnualRevenueLabel"}
                sx={{ mb: 2 }}
              >
                Annual Revenue
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails.AnnualRevenue
                }
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              required
              onChange={props.fieldChangeHandler}
              list={props.annualRevenueRangeList}
              value={props.organization.annualRevenue}
              name={"annualRevenue"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"IndustryLabel"}
                sx={{ mb: 2 }}
              >
                Number of Employees
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails.NumberOfEmployees
                }
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              onChange={props.fieldChangeHandler}
              list={props.NoOfEmployeesRangeList}
              value={props.organization.noOfEmployees}
              required={true}
              name={"noOfEmployees"}
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Typography
          variant="h6"
          data-testid={"Baseline123GHGEmission"}
          sx={{ fontWeight: "bold", color: "gray" }}
        >
          Business Units
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"IndustryLabel"}
                sx={{ mb: 2 }}
              >
                Number of Business Units
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .NumberOfBusinessUnits
                }
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              onChange={props.AddBusinessUnit}
              list={props.businessUnitList}
              value={props.organization.businessUnits?.length.toString()}
              required={true}
              name={"noOfEmployees"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {props.organization?.businessUnits?.map((businessUnit, index) => (
            <Grid
              item
              xs={6}
              sx={{ mt: 1 }}
              key={"businessunit-" + businessUnit.id}
            >
              <Stack direction={"row"}>
                <Typography variant="body1" noWrap>
                  Unit {index + 1}
                  <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                    *
                  </Typography>
                </Typography>
              </Stack>
              <TextField
                required
                id={"businessUnit" + index}
                inputProps={{ "data-testid": "businessUnit" + index }}
                fullWidth
                margin="normal"
                size="small"
                name={"businessUnit" + index}
                value={businessUnit.name}
                onChange={(e) => {
                  props.UpdateBusinessUnit(e, index);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <br />
        <Divider />
        <br />
        <Typography
          variant="h6"
          data-testid={"Baseline123GHGEmission"}
          sx={{ fontWeight: "bold", color: "gray" }}
        >
          Baseline 1, 2 & 3 GHG Emission
        </Typography>
        <Blurb
          infoText={
            TitleDefinitionConstants.OrganizationDetails
              .BaselineScopeEmissionNote
          }
          title={"Note:"}
        ></Blurb>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Baseline Scope 1 GHG Emission (metric tons CO₂e)
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails.BaselineScope1
                }
                placement="auto"
              />
            </Stack>
            <TextField
              required
              {...register("scope1GHGEmission", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="scope1GHGEmission"
              inputProps={{ "data-testid": "scope1GHGEmission" }}
              fullWidth
              margin="normal"
              size="small"
              name={"scope1GHGEmission"}
              value={props.organization.scope1GHGEmission?.toLocaleString(
                "en-US"
              )}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.scope1GHGEmission}
              helperText={errors.scope1GHGEmission?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Baseline Scope 2 GHG Emission (metric tons CO₂e)
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails.BaselineScope2
                }
                placement="auto"
              />
            </Stack>
            <TextField
              required
              {...register("scope2GHGEmission", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="scope2GHGEmission"
              inputProps={{ "data-testid": "scope2GHGEmission" }}
              fullWidth
              margin="normal"
              size="small"
              name={"scope2GHGEmission"}
              value={props.organization.scope2GHGEmission?.toLocaleString(
                "en-US"
              )}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.scope2GHGEmission}
              helperText={errors.scope2GHGEmission?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Baseline Scope 3 GHG Emission (metric tons CO₂e)
                <Typography component="span" sx={{ color: "red", mr: 0.5 }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails.BaselineScope3
                }
                placement="auto"
              />
            </Stack>
            <TextField
              id="scope3GHGEmission"
              inputProps={{ "data-testid": "scope3GHGEmission" }}
              fullWidth
              margin="normal"
              size="small"
              name={"scope3GHGEmission"}
              value={formatValueForDisplay(props.organization.scope3GHGEmission)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              disabled={true}
              placeholder={"Fill in data by category below"}
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Typography
          variant="h6"
          data-testid={"BaselineScope3GHGEmission"}
          sx={{ fontWeight: "bold", mb: 1, color: "gray" }}
        >
          Baseline Scope 3 GHG Emission by Categories
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 1: Purchased Goods and Services (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category1
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("purchasedGoodsAndServices", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="purchasedGoodsandServices"
              inputProps={{ "data-testid": "purchasedGoodsandServices" }}
              fullWidth
              margin="normal"
              size="small"
              name={"purchasedGoodsAndServices"}
              value={formatValueForDisplay(props.organization.purchasedGoodsAndServices)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.purchasedGoodsAndServices}
              helperText={errors.purchasedGoodsAndServices?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 2: Capital Goods (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category2
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("capitalGoods", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="capitalGoods"
              inputProps={{ "data-testid": "capitalGoods" }}
              fullWidth
              margin="normal"
              size="small"
              name={"capitalGoods"}
              value={formatValueForDisplay(props.organization.capitalGoods)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.capitalGoods}
              helperText={errors.capitalGoods?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 3: Fuel- And Energy-Related Activities (metric tons
                CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category3
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("fuelandEnergyRelatedActivities", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="fuelandEnergyRelatedActivities"
              inputProps={{
                "data-testid": "fuelandEnergyRelatedActivities",
              }}
              fullWidth
              margin="normal"
              size="small"
              name={"fuelandEnergyRelatedActivities"}
              value={formatValueForDisplay(props.organization.fuelandEnergyRelatedActivities)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.fuelandEnergyRelatedActivities}
              helperText={errors.fuelandEnergyRelatedActivities?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 4: Upstream Transportation & Distribution (metric tons
                CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category4
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("upstreamTransportationAndDistribution", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="upstreamTransportationAndDistribution"
              inputProps={{
                "data-testid": "upstreamTransportationAndDistribution",
              }}
              fullWidth
              margin="normal"
              size="small"
              name={"upstreamTransportationAndDistribution"}
              value={formatValueForDisplay(props.organization.upstreamTransportationAndDistribution)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.upstreamTransportationAndDistribution}
              helperText={errors.upstreamTransportationAndDistribution?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 5: Waste Generated in Operations (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category5
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("wasteGeneratedinOperations", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="wasteGeneratedinOperations"
              inputProps={{ "data-testid": "wasteGeneratedinOperations" }}
              fullWidth
              margin="normal"
              size="small"
              name={"wasteGeneratedinOperations"}
              value={formatValueForDisplay(props.organization.wasteGeneratedinOperations)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.wasteGeneratedinOperations}
              helperText={errors.wasteGeneratedinOperations?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 6: Business Travel (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category6
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("businessTravel", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="businessTravel"
              inputProps={{ "data-testid": "businessTravel" }}
              fullWidth
              margin="normal"
              size="small"
              name={"businessTravel"}
              value={formatValueForDisplay(props.organization.businessTravel)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.businessTravel}
              helperText={errors.businessTravel?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 7: Employee Commuting (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category7
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("employeeCommuting", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="employeeCommuting"
              inputProps={{ "data-testid": "employeeCommuting" }}
              fullWidth
              margin="normal"
              size="small"
              name={"employeeCommuting"}
              value={formatValueForDisplay(props.organization.employeeCommuting)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.employeeCommuting}
              helperText={errors.employeeCommuting?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 8: Upstream Leased Assets (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category8
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("upstreamLeasedAssets", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="upstreamLeasedAssets"
              inputProps={{ "data-testid": "upstreamLeasedAssets" }}
              fullWidth
              margin="normal"
              size="small"
              name={"upstreamLeasedAssets"}
              value={formatValueForDisplay(props.organization.upstreamLeasedAssets)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.upstreamLeasedAssets}
              helperText={errors.upstreamLeasedAssets?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 9: Downstream Transportation And Distribution (metric
                tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category9
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("downstreamTransportationAndDistribution", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="downstreamTransportationAndDistribution"
              inputProps={{
                "data-testid": "downstreamTransportationAndDistribution",
              }}
              fullWidth
              margin="normal"
              size="small"
              name={"downstreamTransportationAndDistribution"}
              value={formatValueForDisplay(props.organization.downstreamTransportationAndDistribution)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.downstreamTransportationAndDistribution}
              helperText={
                errors.downstreamTransportationAndDistribution?.message
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 10: Processing of Sold Products (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category10
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("processingofSoldProducts", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="processingofSoldProducts"
              inputProps={{ "data-testid": "processingofSoldProducts" }}
              fullWidth
              margin="normal"
              size="small"
              name={"processingofSoldProducts"}
              value={formatValueForDisplay(props.organization.processingofSoldProducts)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.processingofSoldProducts}
              helperText={errors.processingofSoldProducts?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 11: Use of Sold Products (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category11
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("useofSoldProducts", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="useofSoldProducts"
              inputProps={{ "data-testid": "useofSoldProducts" }}
              fullWidth
              margin="normal"
              size="small"
              name={"useofSoldProducts"}
              value={formatValueForDisplay(props.organization.useofSoldProducts)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.useofSoldProducts}
              helperText={errors.useofSoldProducts?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 12: End of Life Treatment of Sold Products (metric tons
                CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category12
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("endofLifeTreatmentofSoldProducts", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="endofLifeTreatmentofSoldProducts"
              inputProps={{
                "data-testid": "endofLifeTreatmentofSoldProducts",
              }}
              fullWidth
              margin="normal"
              size="small"
              name={"endofLifeTreatmentofSoldProducts"}
              value={formatValueForDisplay(props.organization.endofLifeTreatmentofSoldProducts)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.endofLifeTreatmentofSoldProducts}
              helperText={errors.endofLifeTreatmentofSoldProducts?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 13: Downstream Leased Assets (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category13
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("downstreamLeasedAssets", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="downstreamLeasedAssets"
              inputProps={{ "data-testid": "downstreamLeasedAssets" }}
              fullWidth
              margin="normal"
              size="small"
              name={"downstreamLeasedAssets"}
              value={formatValueForDisplay(props.organization.downstreamLeasedAssets)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.downstreamLeasedAssets}
              helperText={errors.downstreamLeasedAssets?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 14: Franchises (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category14
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("franchises", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="franchises"
              inputProps={{ "data-testid": "franchises" }}
              fullWidth
              margin="normal"
              size="small"
              name={"franchises"}
              value={formatValueForDisplay(props.organization.franchises)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.franchises}
              helperText={errors.franchises?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 15: Investments (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category15
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("investments", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="investments"
              inputProps={{ "data-testid": "investments" }}
              fullWidth
              margin="normal"
              size="small"
              name={"investments"}
              value={formatValueForDisplay(props.organization.investments)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.investments}
              helperText={errors.investments?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 16: Other Upstream Scope 3 Emissions (metric tons CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category16
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("otherUpstream", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="otherUpstream"
              inputProps={{ "data-testid": "otherUpstream" }}
              fullWidth
              margin="normal"
              size="small"
              name={"otherUpstream"}
              value={formatValueForDisplay(props.organization.otherUpstream)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.otherUpstream}
              helperText={errors.otherUpstream?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction={"row"}>
              <Typography variant="body1" noWrap>
                Category 17: Other Downstream Scope 3 Emissions (metric tons
                CO₂e)
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.OrganizationDetails
                    .BaselineScope3Category17
                }
                placement="auto"
              />
            </Stack>
            <TextField
              {...register("otherDownstream", {
                pattern: {
                  value: /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
                  message: "Emission value must be a positive numeric",
                },
                maxLength: {
                  value: 8,
                  message: "The value exceeds 8 digits.",
                },
              })}
              id="otherDownstream"
              inputProps={{ "data-testid": "otherDownstream" }}
              fullWidth
              margin="normal"
              size="small"
              name={"otherDownstream"}
              value={formatValueForDisplay(props.organization.otherDownstream)}
              onChange={(e) => {
                props.fieldChangeHandler(e);
              }}
              error={!!errors.otherDownstream}
              helperText={errors.otherDownstream?.message}
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container justifyContent="flex-end">
          <Button type="submit" variant="contained" data-testid="btnSave">
            Save Organization Details
          </Button>
        </Grid>
      </Box>
    </form>
  );
}
