import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Grid, Paper } from "@mui/material";
import { truncateText } from "./data/MaginalAbatementResult";

type RawData = {
  year: string;
  [key: string]: number | string;
};

type ProcessedData = {
  year: Date;
  [key: string]: number | Date;
};

type ConsolidatedStackedAreaChatProps = {
  data: RawData[];
  width: number;
  height: number;
  chartType: "InteractiveGlidepath" | "TimelineAndFinancialMetrics";
};

const ConsolidatedStackedAreaChat: React.FC<
  ConsolidatedStackedAreaChatProps
> = ({ data, width, height, chartType }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!data.length) return;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const margin = { top: 60, right: 60, bottom: 60, left: 60 }; // Adjusted margins for labels
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const parseYear = d3.timeParse("%Y");
    const formatYear = d3.timeFormat("%Y");

    const processedData: ProcessedData[] = data.map((d) => ({
      ...d,
      year: parseYear(d.year) as Date,
    }));

    const keys = Object.keys(data[0]).filter((d) => d !== "year");

    const stack = d3
      .stack<ProcessedData>()
      .keys(keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const series = stack(processedData);

    const x = d3
      .scaleTime()
      .domain(d3.extent(processedData, (d) => d.year) as [Date, Date])
      .range([0, chartWidth]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1])) as number])
      .nice()
      .range(
        chartType === "InteractiveGlidepath"
          ? [chartHeight, 10]
          : [0, chartHeight]
      );

    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(keys);

    svg.selectAll("*").remove();

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left + 40},${margin.top + 30})`);

    // Create the area generator
    const area = d3
      .area<d3.SeriesPoint<ProcessedData>>()
      .x((d) => x(d.data.year))
      .y0((d) => y(d[0]))
      .y1((d) => y(d[1]));

    // Append the areas
    g.append("g")
      .selectAll("path")
      .data(series)
      .enter()
      .append("path")
      .attr("fill", (d) => color(d.key))
      .attr("d", area)
      .on("mouseover", function (event, d) {
        const year = x.invert(d3.pointer(event, this)[0]);
        const tooltipData = processedData.find(
          (dataPoint) => formatYear(dataPoint.year) === formatYear(year)
        );

        let tooltipContent = `<strong>Year: ${formatYear(year)}</strong><br>`;
        if (tooltipData) {
          keys.forEach((key) => {
            tooltipContent += `<span style="color: ${color(key)};">${key}: ${
              tooltipData[key]
            }</span><br>`;
          });
        }

        d3.select(tooltipRef.current)
          .html(tooltipContent)
          .style("opacity", 1)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`);
      })
      .on("mouseout", function () {
        d3.select(tooltipRef.current).style("opacity", 0);
      });

    // Append the x-axis
    g.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", `translate(2,${chartHeight})`)
      .call(d3.axisBottom(x).tickFormat(formatYear as any))
      .selectAll("text")
      .style("font-size", "12px")
      .style("fill", "#7A7A9D");

    // Append the y-axis
    g.append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(y).ticks(10).tickPadding(10))
      .attr("transform", `translate(1, 0)`) // Adjusted position to the right
      .selectAll("text")
      .style("font-size", "12px")
      .style("fill", "#7A7A9D");

    // Append the y-axis label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.left / 3) // Adjusted position
      .attr("x", -(margin.top + height / 2.5))
      .style("text-anchor", "middle")
      .text(
        chartType === "InteractiveGlidepath"
          ? "GHG Emissions Reduction"
          : "Annual Emission Reduction (MT CO₂e / yr)"
      )
      .style("font-size", "18px")
      .style("fill", "#425466");

    // Create the legend container
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${margin.left},${margin.top - 40})`);

    const legendItemWidth = 200; // Increased width for better spacing
    const legendItemHeight = 30; // Adjusted height
    const legendSpacing = 10; // Added spacing between items
    const legendItemsPerRow = Math.floor(
      chartWidth / (legendItemWidth + legendSpacing)
    );

    // Append legend items
    const legendItem = legend
      .selectAll(".legend-item")
      .data(keys)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => {
        const x = (i % legendItemsPerRow) * (legendItemWidth + legendSpacing);
        const y = Math.floor(i / legendItemsPerRow) * legendItemHeight;
        return `translate(${x},${y - 23})`;
      });

    // Append colored rectangles
    legendItem
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 18)
      .attr("height", 18)
      .attr("fill", (d) => color(d));

    // Append text with truncation and tooltip
    legendItem
      .append("text")
      .attr("x", 29)
      .attr("y", 9)
      .attr("dy", "0.39em")
      .style("font-size", "14px")
      .text((d) => truncateText(d, 25)) // Truncate text
      .append("title") // Add full text as tooltip
      .text((d) => d);
  }, [data, height, width]);

  return (
    <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
      <Paper variant="outlined">
        <Grid item sx={{ m: 1 }}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              color: "#05004E",
              width: "100%",
              marginLeft: "8px",
            }}
          >
            {chartType === "InteractiveGlidepath"
              ? "Interactive Glidepath"
              : "Timeline and Financial Metrics"}
          </div>
        </Grid>
        <div>
          <svg ref={svgRef}></svg>
          <div
            ref={tooltipRef}
            style={{
              position: "absolute",
              backgroundColor: "white",
              padding: "5px",
              border: "1px solid black",
              borderRadius: "3px",
              pointerEvents: "none",
              opacity: 0,
            }}
          ></div>
        </div>
      </Paper>
    </Grid>
  );
};

export default ConsolidatedStackedAreaChat;
