import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

//Component
import LeftNavigationBar from "components/LeftNavigationBar";
// Routes
import PageRoutes from "routes/page-routes";

/**
 * OrganizationLayout component is the main layout for the organization section.
 * It includes a left navigation bar, a toggle button for the summary bar, and the main content area for page routes.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export default function OrganizationLayout() {
  // Hide and Show Summary Bar
  const [showSummaryBar, setShowSummaryBar] = useState<boolean>(false);

  return (
    <Box sx={{ display: "flex" }} style={{ width: "100%" }}>
      {/* Left Navigation Bar */}
      <LeftNavigationBar />

      {/* Center Component */}
      <Box component="main" sx={{ flexGrow: 1 }} style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4} lg={4} style={{ paddingRight: "10px" }}>
            <Button
              variant="text"
              onClick={(e) => setShowSummaryBar(!showSummaryBar)}
              style={{ float: "right" }}
              data-testid="toggle-summary-button"
            ></Button>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {/* Pages */}
            <PageRoutes />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
