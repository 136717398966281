import { Grid, Typography, Button, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReductionMeasureModel } from "object-models/ReductionMeasures";
import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import { _getReductionMeasure } from "middlewares/ReductionMeasure/ReductionMeasureApi";
import ViewReductionMeasure from "./ReductionMeasureCard/ViewReductionMeasure";
import { useAppState } from "context/appState.context";
import { UserRoles } from "constants/constant";

interface ViewReductionMeasuresProps {
  reductionMeasureIdList: Array<number>;
  onBackClick: () => void;
  onEditMeasureClick: (selectedMeasureId: number) => void;
  selectedReductionMeasureId: number;
  refreshView: boolean;
}

/**
 * ViewReductionMeasuresLayout component displays the detailed view of a reduction measure,
 * allowing navigation between measures, and provides options for editing.
 *
 * @component
 * @param {ViewReductionMeasuresProps} props - Component properties.
 * @returns {JSX.Element} The layout including measure details and navigation controls.
 */
export default function ViewReductionMeasuresLayout(
  props: Readonly<ViewReductionMeasuresProps>
) {
  const defaultReductionMeasure: ReductionMeasureModel = {
    id: 0,
    orgId: 0,
    reductionMeasureId: 0,
    reductionMeasureName: "",
    businessUnit: "",
    dataSources: "",
    measureStage: "",
    measureCategory: "",
    measureType: "",
    assumptions: "",
    measureDescription: "",
    ghgReduction: "",
    ghgCarriers: [],
    startYear: 0,
    endYear: 0,
    pace: 0,
    execution: 0,
    techLife: 0,
    financialLifetime: 0,
    measureTimingNotes: "",
    easeRating: 0,
    reputationRating: 0,
    climateRiskRating: 0,
    biodiversityRating: 0,
    communityRating: 0,
    technologyRedinessRating: 0,
    operationalDisruptionRating: 0,
    healthSafetyRating: 0,
    isActive: false,
  };
  const { userRole } = useAppState();
  const [loading, setLoading] = useState(false);
  const [reductionMeasureIndex, setReductionMeasureIndex] = useState(0);
  const [currentReductionMeasureId, setCurrentReductionMeasureId] = useState(
    props.selectedReductionMeasureId
  );
  const [reductionMeasure, setReductionMeasure] =
    useState<ReductionMeasureModel>(defaultReductionMeasure);

  /**
   * Fetches the details of the current reduction measure from the API.
   */
  async function getReductionMeasureDetails() {
    _getReductionMeasure(currentReductionMeasureId).then((res) => {
      if (res) {
        setReductionMeasure(res);
        setReductionMeasureIndex(
          props.reductionMeasureIdList.findIndex(
            (x) => x === currentReductionMeasureId
          )
        );
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    getReductionMeasureDetails();
  }, [currentReductionMeasureId]);
  useEffect(() => {
    if (props.refreshView === true) {
      setLoading(true);
      getReductionMeasureDetails();
    }
  }, [props.refreshView]);

  /**
   * Moves to the next reduction measure in the list.
   */
  const nextReduction = () => {
    let index = props.reductionMeasureIdList.findIndex(
      (x) => x === currentReductionMeasureId
    );
    if (index !== -1) {
      index = index + 1;
      setReductionMeasureIndex(index);
      if (index < props.reductionMeasureIdList.length) {
        setCurrentReductionMeasureId(props.reductionMeasureIdList[index]);
      }
    }
  };

  /**
   * Moves to the previous reduction measure in the list.
   */
  const previousReduction = () => {
    let index = props.reductionMeasureIdList.findIndex(
      (x) => x === currentReductionMeasureId
    );
    if (index !== -1) {
      index = index - 1;
      setReductionMeasureIndex(index);
      if (index < props.reductionMeasureIdList.length && index > -1) {
        setCurrentReductionMeasureId(props.reductionMeasureIdList[index]);
      }
    }
  };

  /**
   * Renders the layout for viewing a reduction measure, including navigation and action buttons.
   *
   * @returns {JSX.Element} The layout with measure details and navigation controls.
   */
  const renderReductionMeasure = () => {
    return (
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={4} md={6.5}>
          <Typography
            fontSize="16px"
            fontFamily={"Inter"}
            fontWeight={700}
            data-testid="MeasureInformation"
          >
            <IconButton
              color="primary"
              data-testid="backtolistviewbutton"
              onClick={props.onBackClick}
            >
              <ChevronLeftIcon />
            </IconButton>
            Measure Information
          </Typography>
        </Grid>
        <Grid item xs={4} md={5.5} textAlign={"right"}>
          {userRole !== UserRoles.Viewers && (
            <Button
              variant="contained"
              data-testid="EditReductionMeasureButton"
              onClick={() => {
                props.onEditMeasureClick(currentReductionMeasureId);
              }}
            >
              Edit Measure
            </Button>
          )}
        </Grid>
        {!loading ? (
          <ViewReductionMeasure reductionMeasure={reductionMeasure} />
        ) : (
          <Spinner size={80} data-testid="spinner" />
        )}
        <Grid item xs={12}>
          <Grid item xs={12} textAlign={"right"}>
            <Button
              sx={{ mr: 5 }}
              variant="outlined"
              data-testid="PreviousMeasureButton"
              disabled={reductionMeasureIndex <= 0}
              onClick={previousReduction}
            >
              Previous Measure
            </Button>
            <Button
              sx={{ mr: 10 }}
              variant="contained"
              data-testid="NextMeasureButton"
              onClick={nextReduction}
              disabled={
                reductionMeasureIndex == props.reductionMeasureIdList.length - 1
              }
            >
              Next Measure
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <React.Fragment>{renderReductionMeasure()}</React.Fragment>;
}
