import Alert from '@mui/material/Alert';

// types
import {AlertsProps} from "./data/Alerts.type"

/**
 * Alerts component to display a MUI Alert with a given severity and message.
 *
 * @param {AlertsProps} props - The properties for the Alert component.
 * @returns {JSX.Element} The rendered Alerts component.
 */
const Alerts = (props : AlertsProps) => {
    return (
        <Alert severity={props.severity}>{props.message}</Alert>
    )
}

export default Alerts