import apiHandler from "middlewares/apiHandler";
import { UserRoleConfiguration } from "pages/Settings/UserRole/data/UserRoleConfiguration";

export const _getUserRoleConfiguration = async (orgId: number | undefined) => {
  return apiHandler.get<UserRoleConfiguration>(
    `organization/${orgId}/userroleconfiguration`
  );
};

export const _addUserRoleConfiguration = async (model: UserRoleConfiguration) => {
  return apiHandler.post<UserRoleConfiguration>("userroleconfiguration", model);
};
