import React from "react";
import { TableRow, TableCell, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { PriceModel } from "./data/PriceModel";

interface GrowthRowProps {
  priceData: PriceModel[];
  onChange: (id: string, value: string) => void;
  isUserRole: boolean;
}

const CustomTableCell = styled(TableCell)({
  padding: "9px", // Adjust the padding as needed
});

/**
 * Component that renders a table row with `TextField` inputs for growth values for each `PriceModel` item.
 *
 * @param {GrowthRowProps} props - The props for the component.
 * @param {PriceModel[]} props.priceData - The array of `PriceModel` objects containing the data to display.
 * @param {(id: string, value: string) => void} props.onChange - The callback function to handle changes in `TextField` values.
 * @returns {React.ReactElement} - A `TableRow` element containing `TextField` inputs for growth values.
 */
const GrowthRow: React.FC<GrowthRowProps> = ({
  priceData,
  onChange,
  isUserRole
}: GrowthRowProps): React.ReactElement => (
  <TableRow>
    <CustomTableCell>Growth</CustomTableCell>
    {priceData.map((item) => (
      <CustomTableCell key={`growth-${item.uniqueId}`}>
        <TextField
          value={item.growth !== undefined ? item.growth.toString() : "0"}
          id={`growth-${item.uniqueId}`}
          onChange={(e) => onChange(item.uniqueId, e.target.value)}
          type="number"
          size="small"
          fullWidth
          inputProps={{
            "data-testid": `growthId-${item.uniqueId}`,
            inputMode: "numeric",
            maxLength: 3,
          }}
          disabled={!isUserRole}
        />
      </CustomTableCell>
    ))}
  </TableRow>
);

export default GrowthRow;
