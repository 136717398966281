import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

//type
import { SearchProps } from "./data/SearchProps.type";

/**
 * Search component for rendering a search input with a search icon button.
 *
 * @param {SearchProps} props - The props for the Search component.
 * @returns {JSX.Element} - Rendered Search component.
 */
const Search = ({ label, searchFunction }: SearchProps) => {
  return (
    <Paper>
      <IconButton>
        <SearchIcon />
      </IconButton>
      <InputBase
        style={{ width: "80%" }}
        placeholder={label}
        onChange={searchFunction}
        data-testid="search-input"
      />
    </Paper>
  );
};

export default Search;
