// External dependencies
import React, { useEffect, useState } from "react";

// Material-UI components
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { OrganizationMapping } from "pages/HomePage/data/HomePage.type";
import Spinner from "components/Spinner";
import { userRoleModel } from "./data/userRoleModel";
import { _updateUser } from "middlewares/ManageUsers/manageusers";
import { AlertsProps } from "components/Alerts/data/Alerts.type";

interface AssignOrganizationProps {
  hideDialogHandler: () => void;
  getUserRoles: () => void;
  userRoleModel?: userRoleModel | null;
  handleCloseSnackBar: (state: AlertsProps) => void;
  organizations: Array<OrganizationMapping>;
}

/**
 * AssignOrganization component
 * 
 * This component renders a list of organizations with checkboxes to assign them to a user.
 * It handles the selection and deselection of organizations and saves the changes.
 * 
 * @param {AssignOrganizationProps} props - The props for the component.
 * @param {Function} props.hideDialogHandler - Function to hide the dialog.
 * @param {Function} props.getUserRoles - Function to fetch user roles.
 * @param {userRoleModel | null} [props.userRoleModel] - Optional data for pre-filling the form when editing a user.
 * @param {Function} props.handleCloseSnackBar - Function to handle closing the snackbar.
 * @param {Array<OrganizationMapping>} props.organizations - List of organizations to display.
 * @returns {JSX.Element} A component for assigning organizations to a user.
 */
const AssignOrganization = (props: AssignOrganizationProps) => {
  const [organizations, setOrganizations] = useState<
    Array<OrganizationMapping>
  >([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState<string>("");
  const [user, setUser] = useState<userRoleModel>(
    props.userRoleModel ?? {
      userRoleId: 0,
      userName: "",
      userEmail: "",
      company: "",
      roleId: "",
      orgId: 0,
      organizationMapping: "",
    }
  );
  
  useEffect(() => {
    setOrganizations((prevOrganizations) => {
      const organizationMapping = user.organizationMapping ?? ""; // Default to empty string if null
      const updatedOrganizations = props.organizations.map((org) => ({
        ...org,
        isAssigned: organizationMapping.split(",").includes(String(org.orgId)),
      }));
      return updatedOrganizations;
    });
  }, [props.organizations]);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      organizationMapping: selectedOrgs,
    }));
  }, [selectedOrgs]);

  /**
   * Toggles the assignment status of an organization.
   * 
   * @param {OrganizationMapping} org - The organization to toggle.
   * @param {number} index - The index of the organization in the list.
   * @returns {OrganizationMapping} The updated organization with toggled assignment status.
   */
  const toggleOrganizationAssignment = (org: OrganizationMapping, index: number) => {
    return {
      ...org,
      isAssigned: !org.isAssigned,
    };
  };

  /**
   * Gets the IDs of the selected organizations as a comma-separated string.
   * 
   * @param {OrganizationMapping[]} updatedOrganizations - The updated list of organizations.
   * @returns {string} The IDs of the selected organizations.
   */
  const getSelectedOrgs = (updatedOrganizations: OrganizationMapping[]) => {
    return updatedOrganizations
      .filter((org) => org.isAssigned)
      .map((org) => org.orgId)
      .join(",");
  };

  /**
   * Handles the change event of the organization checkboxes.
   * 
   * @param {OrganizationMapping} org - The organization whose checkbox was changed.
   * @param {number} index - The index of the organization in the list.
   */
  const handleCheckboxChange = (org: OrganizationMapping, index: number) => {
    setOrganizations((prevOrganizations) => {
      const updatedOrganizations = [...prevOrganizations];
      updatedOrganizations[index] = toggleOrganizationAssignment(org, index);
      const newSelectedOrgs = getSelectedOrgs(updatedOrganizations);
      setSelectedOrgs(newSelectedOrgs);
      return updatedOrganizations;
    });
  };

   /**
   * Handles the save button click event, updating the user with the selected organizations.
   */
  const handleSaveButtonClick = async () => {
    try {
      setLoading(true);
      const res = await _updateUser(user);
      if (res) {
        setLoading(false);
        const message = 'Data Saved Successfully !';
        props.handleCloseSnackBar({ show: true, severity: 'success', message: message });
        props.hideDialogHandler();
        props.getUserRoles();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  /**
   * Renders the list of organizations with checkboxes.
   * 
   * @returns {JSX.Element} The rendered list of organizations.
   */
  const renderOrganizations = () => (
    <List>
      {organizations.map(
        (org, index) =>
          org.orgName !== "" && (
            <ListItem key={org.orgId} >
              <Checkbox
                checked={org.isAssigned || false}
                onClick={() => handleCheckboxChange(org, index)}
                data-testid={`orgCheckbox-${org.orgId}`}
              />
              <ListItemText primary={org.orgName} data-testid="orgName" />
            </ListItem>
          )
      )}
    </List>
  );

  // JSX structure for the AddUser component
  return (
    <Box>
      <Grid item xs={12} md={12}>
        <br />
        {loading ? (
          <Spinner size={80} data-testid="spinner" />
        ) : (
          renderOrganizations()
        )}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveButtonClick}
        data-testid="savebutton"
      >
        Save
      </Button>
    </Box>
  );
};

export default AssignOrganization;
