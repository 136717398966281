import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      text: "GHG Emissions",
    },
    title: {
      display: true,
      text: "Business As Usual Emissions",
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Year",
      },
      grid: {
        display: false,
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "GHG Emissions (metric tons CO₂e)",
      },
    },
  },
};

interface LineChartProps {
    labels: string[];
    data: number[];
  }

export function LineChart(props: Readonly<LineChartProps>) {
const labels = props.labels;
  const data = {
    labels,
    datasets: [
      {
        label: "GHG Emissions",
        data: props.data,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return <Line options={options} data={data} />;
}
