import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface Option {
  id: number;
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  onSelectionChange: (selectedOptions: number[]) => void;
  selectedOptions: number[];
  dataTestId?: string;
}

const MultSelectNew: React.FC<MultiSelectProps> = ({
  options,
  onSelectionChange,
  selectedOptions,
  dataTestId = "multi-select",
}) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [internalSelectedOptions, setInternalSelectedOptions] =
    useState<number[]>(selectedOptions);

  useEffect(() => {
    setInternalSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  const handleOptionChange = (event: SelectChangeEvent<number[]>) => {
    const selectedValues = event.target.value as number[];

    let newSelectedOptions: number[];

    if (selectedValues.includes(0)) {
      newSelectedOptions =
        internalSelectedOptions.length === options.length - 1
          ? []
          : options
              .filter((option) => option.id !== 0)
              .map((option) => option.id);
    } else {
      newSelectedOptions = selectedValues.filter((value) => value !== -1);
    }

    // Always update internal state immediately
    setInternalSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);

    // Set validity after updating state
    setIsValid(newSelectedOptions.length > 0);
  };

  const isAllSelected = internalSelectedOptions.length === options.length - 1;

  const renderValue = (selected: number[]) => {
    if (selected.length === 0 || selected.includes(-1)) {
      return "Select Organizations"; // Show this by default if nothing is selected
    } else if (isAllSelected) {
      return "All Selected";
    } else {
      return selected
        .map((value) => options.find((option) => option.id === value)?.label)
        .join(", ");
    }
  };

  return (
    <FormControl fullWidth error={!isValid}>
      <Select
        labelId="multi-select-label"
        id="multi-select"
        multiple
        value={internalSelectedOptions}
        onChange={handleOptionChange}
        size="small"
        data-testid={dataTestId}
        renderValue={renderValue}
      >
        <MenuItem value={-1}>Select Organizations</MenuItem>
        <MenuItem key={0} value={0}>
          <Checkbox checked={isAllSelected} />
          Select All
        </MenuItem>
        {options
          .filter((option) => option.id !== 0)
          .map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={internalSelectedOptions.includes(option.id)} />
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default MultSelectNew;
