import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ToolTipMessage from "components/Tooltip";

/**
 * Interface for the TitleDefinitionModel props.
 */
export interface TitleDefinitionModel {
  content: string;
  placement: string;
}

/**
 * TitleDefinition component displays an InfoOutlinedIcon with a tooltip.
 *
 * @param {Readonly<TitleDefinitionModel>} props - The props for the component.
 * @returns {JSX.Element} The rendered TitleDefinition component.
 */
export default function TitleDefinition(props: Readonly<TitleDefinitionModel>) {
  return (
    <ToolTipMessage
      title={props.content}
      placement={props.placement}
      component={
        <InfoOutlinedIcon
          fontSize="small"
          sx={{ color: "#e4610f", mx: 0.4, my: 0.2 }}
        />
      }
    ></ToolTipMessage>
  );
}
