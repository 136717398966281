import { useNavigate } from "react-router-dom";

import {
  Box,
  Fab,
  Fade,
  Typography,
  Grid,
  Button,
  AppBar,
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  Hidden
} from "@mui/material";
//icon
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Import Type
import { LayoutProps } from "./data/AppLayout.type";
// Logo
import nzdmlogo from "assets/Images/NZC_ident_black.png";
import packageInfo from "../../../package.json";
import LoggedInPersonName from "./header/logged-in-person-name.component";
import LoggedInAvatarMenu from "./header/logged-in-avatar-menu.component";

/**
 * ScrollTop component to handle scrolling back to the top when a trigger is activated.
 *
 * @param {LayoutProps} props - The properties for the ScrollTop component.
 * @returns {JSX.Element} The rendered ScrollTop component.
 */
function ScrollTop(props:Readonly<LayoutProps>) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  // Use the scroll trigger to check if the user has scrolled more than 100px
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Button
        onClick={handleClick}
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        aria-label="Clickable container"
      >
        {children}
      </Button>
    </Fade>
  );
}

/**
 * AppLayout component to define the main layout structure for the application.
 *
 * @param {LayoutProps} props - The properties for the AppLayout component.
 * @returns {JSX.Element} The rendered AppLayout component.
 */
export default function AppLayout(props:Readonly<LayoutProps>) {
  const Navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <img
                src={nzdmlogo}
                alt={"arcadis logo"}
                height={45}
                style={{ marginTop: "10px" }}
              />
            </Grid>

            <Grid item xs={6}>
              {props.isAuthorized ? (
                <Button
                  size="medium"
                  onClick={(e) => Navigate("/organizations")}
                  data-testid="home-button"
                >
                  <HomeOutlinedIcon fontSize="large" />
                </Button>
              ) : null}
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end">
                <Hidden only="xs">
                  {props.isAuthorized ? (
                    <LoggedInPersonName
                      variant="body1"
                      color="black"
                      fontWeight={300}
                      account={props.account}
                    ></LoggedInPersonName>
                  ) : null}
                </Hidden>
              </Box>
            </Grid>
            <Grid item xs={1}>
              {props.isAuthorized ? (
                <LoggedInAvatarMenu
                  account={props.account}
                  signOut={props.signOut}
                ></LoggedInAvatarMenu>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <Box sx={{ m: 2 }}>
        <Grid container>{props.children}</Grid>
      </Box>
      <Grid item>
        <Typography
          variant="caption"
          data-testid="version"
          sx={{
            bottom: "1rem",
            ml: "1rem",
            color: "#e4610f",
          }}
        >
          V {packageInfo.version}
        </Typography>
      </Grid>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}
