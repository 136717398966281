import React from "react";
import { Typography } from "@mui/material";
import TitleDefinition from "components/TitleDefinition";

interface RequiredLabelProps {
  labelText: string;
  isHelperTextRequired: boolean;
  content?: string;
}

/**
 * Component to display a required label with optional helper text.
 * 
 * @param {string} labelText - The text for the label.
 * @param {boolean} isHelperTextRequired - Whether the helper text is required.
 * @param {string} [content] - The content of the helper text.
 * @returns {JSX.Element} - The rendered RequiredLabel component.
 */
const RequiredLabel: React.FC<RequiredLabelProps> = ({
  labelText,
  isHelperTextRequired,
  content,
}) => {
  return (
    <Typography sx={{ fontWeight: 600, pb: 1 }}>
      {labelText}{" "}
      <Typography component="span" sx={{ color: "red" }}>
        *
      </Typography>
      {isHelperTextRequired ? (
        <TitleDefinition
          content={content ?? ""}
          placement="top"
        ></TitleDefinition>
      ) : null}
    </Typography>
  );
};

export default RequiredLabel;
