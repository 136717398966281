import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";

import { OrganizationModel } from "object-models/organization-model";
import EmissionByScopeCategoryBarChart from "pages/Dashboards/BaselineTargetEmission/EmissionByScopeCategoryBar.Chart";
import { DataItem } from "../Data/PortfolioViewData";
import { barData } from "../Data/dataUtils";

interface EmissionByScopeCategoryBarProps {
  data: OrganizationModel;
}

/**
 * A React functional component that renders a bar chart showing emissions categorized by various activities.
 * The chart dynamically updates based on the emission data passed.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {OrganizationModel} props.data - The organization emission data containing values for different categories of emissions.
 */
const EmissionByScopeCategoryBar: React.FC<EmissionByScopeCategoryBarProps> = ({
  data,
}) => {
  const defaultBarChatWidth = 600;
  const CATEGORY_HEIGHT = 38;
  const defaultHeight = 600;

  const [barChartData, setBarChartData] = useState([] as Array<DataItem>);

  /**
   * Generates and returns the data for the bar chart based on the emission data.
   */
  const dataItems = barData(data);

  /**
   * Reshapes the bar chart data by calling `barData` and updating the state.
   *
   * @function
   */
  function reshapeData() {
    setBarChartData(dataItems);
  }

  // Update bar chart data when `data` changes
  useEffect(() => {
    reshapeData();
  }, [data]);

  return (
    <Grid item xs={6.2}>
      <Paper variant="outlined" sx={{ p: 1 }}>
        {" "}
        <EmissionByScopeCategoryBarChart
          width={defaultBarChatWidth}
          categoryHeight={CATEGORY_HEIGHT}
          height={defaultHeight}
          data={barChartData}
        />
      </Paper>
    </Grid>
  );
};
export default EmissionByScopeCategoryBar;
