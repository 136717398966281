import { ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ToolTipMessage from "components/Tooltip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

/**
 * ListItem component represents a single item in a list.
 * It handles navigation and highlighting of the item based on the active route.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {any} props.item - The item object containing details like label, link, icon, etc.
 * @param {boolean} props.open - Boolean indicating whether the navigation menu is open or not.
 * @param {boolean} props.isHighlighted - Boolean indicating whether the item is highlighted.
 * @param {string} props.primary - Primary color for highlighting.
 * @param {string} props.secondary - Secondary color for default state.
 * @returns {JSX.Element} The rendered component.
 */
const ListItem = ({ item, open, isHighlighted, primary, secondary }: any) => {
  const { OrganizationId } = useParams();
  const Navigate = useNavigate();

  const onClickNavigation = (link: string, item: string, mainMenu: string) => {
    localStorage.setItem("Highlights", item);
    localStorage.setItem("HighlightsMenu", mainMenu);
    Navigate(link);
  };

  return (
    <ListItemButton
      data-testid={item.label}
      sx={{ borderRadius: 2 }}
      onClick={() =>
        onClickNavigation(
          `/Organization/${OrganizationId}/${item.link}`,
          item.label,
          item.label
        )
      }
      className={isHighlighted ? "active" : "in-active"}
    >
      <ListItemIcon style={{ color: `${isHighlighted ? primary : secondary} !important` }}>
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primary={item.label}
        primaryTypographyProps={{
          style: {
            color: `${isHighlighted ? primary : secondary}`,
          },
          marginLeft: "-12px",
        }}
      />
      {!open && (
        <ToolTipMessage
          title={item.label}
          placement={"auto"}
          component={
            <ArrowRightIcon
              fontSize="small"
              sx={{ color: `${isHighlighted ? primary : secondary}` }}
            />
          }
        />
      )}
    </ListItemButton>
  );
};

export default ListItem;
