import { Button, Dialog, Grid } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

interface SessionTimeoutModel {
  readonly onContinueSession: () => void;
  readonly onLogOut: () => void;
}

/**
 * SessionTimeout component displays a dialog warning the user about session expiration
 * and providing options to either continue the session or log out.
 *
 * @param {SessionTimeoutModel} props - The props for the component.
 * @returns {JSX.Element} The rendered SessionTimeout component.
 */
function SessionTimeout(props: Readonly<SessionTimeoutModel>) {
  return (
    <Dialog open={true}>
      <Grid container data-testid="sessionTimeout">
        <Grid item xs={3}>
          <WarningAmberRoundedIcon
            style={{ fontSize: "100px" }}
            sx={{ color: "yellow" }}
            data-testid={"session-warning-icon"}
          ></WarningAmberRoundedIcon>
        </Grid>
        <Grid item xs={9} sx={{ mt: 1 }} data-testid={"session-message"}>
          <p>
            Your session is about to expire.
            <br></br> You will be logged out in 60 seconds.
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} alignContent="center" sx={{ p: 1 }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: "45px" }}
            onClick={props.onLogOut}
            data-testid={"session-logout-button"}
          >
            Log out
          </Button>
        </Grid>
        <Grid item xs={6} alignContent="center" sx={{ p: 1 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            sx={{ height: "45px" }}
            onClick={props.onContinueSession}
            data-testid={"session-continue-button"}
          >
            Continue Session
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default SessionTimeout;
