import apiHandler from "middlewares/apiHandler";
import { PortfolioFilter } from "pages/PortfolioView/Data/PortfolioFilter";
import { ProjectRegisterFilter } from "pages/PortfolioView/Data/ProjectRegisterFilter";

export const _getTotalEmissionByScopes = async (model: PortfolioFilter) => {
    return apiHandler.post<any>("dashboard/TotalEmissionByScopes", model);
};

export const _getMultipleOrgProjectRegisterDetails = async (model: ProjectRegisterFilter) => {
  return apiHandler.post<any>("dashboard/MultipleOrgProjectRegisterDetails", model);
};