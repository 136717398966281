export const userRoles = [
    { id: 1, label: "Super Admin", value: "1" },
    { id: 2, label: "Admin", value: "2" },
    { id: 3, label: "Standard User", value: "3" },
    { id: 4, label: "Viewers", value: "4" },
  ];

  export enum UserRole {
    SuperAdmin = 1,
    Admin = 2,
    StandardUser = 3,
    Viewer = 4,
  }