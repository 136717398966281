import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import appStorage from "../utilities/app-storage";

/**
 * Custom hook to handle authentication using MSAL.
 *
 * @param {Object} loginRequest - The login request object containing scopes.
 * @param {string[]} loginRequest.scopes - The scopes for the login request.
 * @returns {string | null} The access token if acquired, otherwise null.
 */
export function useAuth(loginRequest: { scopes: string[] }): string | null {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  useEffect(() => {
    const handleLogin = async () => {
      if (accounts.length === 0 || inProgress !== "none") {
        return;
      }
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
          forceRefresh: true,
        });
        setAccessToken(response.accessToken);
        appStorage.setAccessToken(response.accessToken);
        if (response.expiresOn != null) {
          appStorage.setExpirationTime(response.expiresOn);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleLogin();
  }, [accounts, inProgress, instance, loginRequest]);
  return accessToken;
}