import { CapexPlanResult } from "../Data/CapexPlan";
import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/system";

interface CapexOrgNameRowProps {
  capexPlanResult: CapexPlanResult[];
}

const CustomTableCell = styled(TableCell)({
  padding: "9px", // Adjust the padding as needed
  backgroundColor: "#E9EFF5",
});

const CapexOrgNameRow: React.FC<CapexOrgNameRowProps> = ({
  capexPlanResult,
}: CapexOrgNameRowProps): React.ReactElement => (
  <TableRow>
    <CustomTableCell>Organizations</CustomTableCell>
    {capexPlanResult.map((item) => (
      <CustomTableCell key={`capex-${item.capexModelCost.orgName}`}>
        {item.capexModelCost.orgName}
      </CustomTableCell>
    ))}
  </TableRow>
);

export default CapexOrgNameRow;
