import { PublicClientApplication } from "@azure/msal-browser";
import appStorage from "utilities/app-storage";
import {loginRequest} from "../configs/MsalConfig";
import { Utilities } from "utilities/utilities";
import { msalInstance } from "index";

/**
 * Class to handle authentication operations.
 */
export class AuthOperations {
  publicClientApplication: PublicClientApplication | undefined;

  /**
   * Indicates if a token API call is in progress to avoid multiple token requests.
   * @private
   * @type {boolean}
   */
  private callInProgress = false;

  /**
   * Retrieves a new access token if the current token is about to expire.
   * If the token expires in 10 seconds or less and no token API call is in progress, 
   * it initiates a silent token acquisition process. If the acquisition fails, 
   * the user is logged out.
   */
  getRefreshToken() {
  
    const timeLeft = Utilities.timeLeftInTokenExpiration();

    if (timeLeft <= 10 && !this.callInProgress) {
      this.callInProgress = true;
      const account = msalInstance.getAllAccounts()[0];
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
          forceRefresh: true,
        })
        .then((authResponse) => {
          if (authResponse) {
            appStorage.setAccessToken(authResponse.accessToken);

            if (authResponse.expiresOn != null) {
              appStorage.setExpirationTime(authResponse.expiresOn);
            }
            this.callInProgress = false;
          } else {
            this.callInProgress = false;
            // log out user
            Utilities.redirectToLogout();
          }
        })
        .catch((error) => {
          //Acquire token silent failure

          this.callInProgress = false;
          Utilities.redirectToLogout();
        });
    }
  }
 
}

const authOperations = new AuthOperations();
export default authOperations;