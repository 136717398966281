import { Tooltip } from "@mui/material";
import React from "react";

/**
 * Interface for the ToolTipModel props.
 */
export interface ToolTipModel {
  component: React.ReactElement;
  title: string;
  open?: boolean;
  placement?: any;
}


/**
 * ToolTipMessage component displays a tooltip over a given component.
 *
 * @param {Readonly<ToolTipModel>} props - The props for the component.
 * @returns {JSX.Element} The rendered TooltipMessage component.
 */
export default function ToolTipMessage(props: Readonly<ToolTipModel>) {
  return (
    <Tooltip
      arrow
      title={props.title}
      placement={props.placement ? props.placement : "top"}
      open={props.open}
    >
      {props.component}
    </Tooltip>
  );
}
